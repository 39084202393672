import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import "./CustomComponents.css";

function CustomAutocomplete({height, minWidth, options, value, onChange, backgroundColor, highlightColor, fontSize}){

	// default values for highlightColor and fontSize
	if (highlightColor === undefined) {
		highlightColor = "#5A5A5A";
	}
	if (fontSize === undefined) {
		fontSize = "1rem";
	}

	return(
		<div className="customAutoComplete">
			<Autocomplete
				options={options}
				value={value}
				onChange={onChange}
				getOptionLabel={(option) => option.label || "" }
				isOptionEqualToValue={(option, value) => option.value === value.value || value.value === 0}
				sx={{
					"& .MuiOutlinedInput-root": {
						borderRadius: "10px",
						padding: "0px",
						border: "1px solid white",
						height: height,
						minWidth: minWidth,
						paddingLeft: "10px",
						backgroundColor: backgroundColor
					},
					'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						border: "1px solid white",
						outline: 'none !important',
					},
					'.MuiSvgIcon-root ': {
						fill: "white !important",
					},
					"& button.MuiButtonBase-root" : {
						visibility: "visible"
					},
					'& ::selection': {
						backgroundColor: highlightColor,
					},
					'.MuiInputBase-input':{
						borderRadius: "0px",
					},
					"@media (max-width: 600px)": {
						"& .MuiOutlinedInput-root": {
							minWidth: "none",
						}
					}
				}}
				renderInput={(params) => 
					<TextField 
						sx={{
							input: {
								color: 'white',
								fill:"black",
								fontSize: fontSize,
							}
						}} 
						{...params}
						margin="none"
						label=""
						variant='outlined'
					/>
				}
			/>
		</div>
	);
}

export default CustomAutocomplete;