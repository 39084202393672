import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import ReviewStats from "../ReviewPage/ReviewStats";
import PageNotFound from "../Other/PageNotFound";
import { Tab, Tabs } from "@mui/material";
import "./DepartmentHomePage.css";
import CustomLinearProgress from "../CustomComponents/CustomLinearProgress";
import OverallStats from "../ReviewPage/OverallStats";
import ReviewPageTitleStats from "../ReviewPage/ReviewPageTitles/ReviewPageTitleStats";
import {
    ALL,
    COURSES_BY_DEPARTMENT,
    CURRENT,
    DEPARTMENT_TITLE_STATS,
    GET_DEPARTMENT,
    PROFESSORS_BY_DEPARTMENT,
} from "../../util/apiRoutes";
import { customFetch } from "../../util/customFetch";

function DepartmentHomePage() {
    const [allProfessorData, setAllProfessorData] = useState([]);
    const [currentProfessorData, setCurrentProfessorData] = useState([]);
    const [filterProfessors, setFilterProfessors] = useState(true);

    const [allCourseData, setAllCourseData] = useState([]);
    const [currentCourseData, setCurrentCourseData] = useState([]);
    const [filterCourses, setFilterCourses] = useState(true);

    const [department, setDepartment] = useState({
        departmentName: "",
        departmentAbbreviation: "",
        departmentId: 0,
    });

    const [departmentOverallRating, setDepartmentOverallRating] = useState(-1);
    const [departmentPercentRecommended, setDepartmentPercentRecommended] =
        useState(-1);

    const [mobileTabValue, setMobileTabValue] = useState("stats"); // courses, professors, or stats
    const handleChange = (event, newValue) => {
        setMobileTabValue(newValue);
    };

    const [midSizeTabValue, setMidSizeTabValue] = useState("professors"); // courses, professors, or stats
    const handleMidSizeChange = (event, newValue) => {
        setMidSizeTabValue(newValue);
    };

    const { departmentId } = useParams();

    let departmentUrl = GET_DEPARTMENT + departmentId.toString();
    let coursesUrl = COURSES_BY_DEPARTMENT + departmentId.toString();
    let professorsUrl = PROFESSORS_BY_DEPARTMENT + departmentId.toString();

    let deparmentTitleStatsUrl = DEPARTMENT_TITLE_STATS + departmentId.toString();

    const [titleLoading, setTitleLoading] = useState(true);
    const [professorsLoading, setProfessorsLoading] = useState(true);
    const [coursesLoading, setCoursesLoading] = useState(true);

    const [departmentNotFound, setDepartmentNotFound] = useState(false);

    const professorsColumn = (
        <div className="professorsColumn">
            <h1 className="department-column-title department-tab-title">
                Professors
            </h1>
            <div className="current-filter-buttons">
                <button
                    className={filterProfessors ? "filter-selected" : ""}
                    onClick={() => handleFilterProfessors(true)}
                >
                    Current
                </button>
                <button
                    className={filterProfessors ? "" : "filter-selected"}
                    onClick={() => handleFilterProfessors(false)}
                >
                    All
                </button>
            </div>
            {professorsLoading ? (
                <CustomLinearProgress
                    lightMode={true}
                    minHeight={356}
                    minWidth={185}
                />
            ) : (
                <div className="professors">
                    {!filterProfessors ? (
                        <>
                            {allProfessorData.map((professor) => (
                                <Link
                                    key={professor.id}
                                    className="link"
                                    to={"/reviews/prof/" + professor.id}
                                >
                                    {professor.fullName}
                                </Link>
                            ))}
                        </>
                    ) : (
                        <>
                            {currentProfessorData.map((professor) => (
                                <Link
                                    key={professor.id}
                                    className="link"
                                    to={"/reviews/prof/" + professor.id}
                                >
                                    {professor.fullName}
                                </Link>
                            ))}
                        </>
                    )}
                </div>
            )}
        </div>
    );

    const coursesColumn = (
        <div className="coursesColumn">
            <h1 className="department-column-title department-tab-title">
                Courses
            </h1>
            <div className="current-filter-buttons">
                <button
                    className={filterCourses ? "filter-selected" : ""}
                    onClick={() => handleFilterCourses(true)}
                >
                    Recently taught
                </button>
                <button
                    className={filterCourses ? "" : "filter-selected"}
                    onClick={() => handleFilterCourses(false)}
                >
                    All
                </button>
            </div>
            {coursesLoading ? (
                <CustomLinearProgress
                    lightMode={true}
                    minHeight={356}
                    minWidth={450}
                />
            ) : (
                <div className="courses">
                    {!filterCourses ? (
                        <>
                            {allCourseData.map((course) => {
                                let link = "/reviews/course/" + course.id;
                                return (
                                    <Link
                                        to={link}
                                        className="link"
                                        key={course.id}
                                    >
                                        {<div className="course-line">
                                            <div className="bold-abbr">
                                                {course.fullNameStr.split(":")[0].split("/").map((number) => {
                                                    return <div>{number}</div>
                                                })}
                                            </div>
                                            <div className="course-line">{course.fullNameStr.split(":")[1]}</div>
                                        </div>}
                                    </Link>
                                );
                            })}
                        </>
                    ) : (
                        <>
                            {currentCourseData.map((course) => {
                                let link = "/reviews/course/" + course.id;
                                return (
                                    <Link
                                        to={link}
                                        className="link"
                                        key={course.id}
                                    >
                                        {<div className="course-line">
                                            <div className="bold-abbr">
                                                {course.fullNameStr.split(":")[0].split("/").map((number) => {
                                                    return <div>{number}</div>
                                                })}
                                            </div>
                                            <div className="course-line">{course.fullNameStr.split(":")[1]}</div>
                                        </div>}
                                    </Link>
                                );
                            })}
                        </>
                    )}
                </div>
            )}
        </div>
    );

    useEffect(() => {
        customFetch(coursesUrl + ALL, {})
            .then((res) => res.json())
            .then((courseData) => {
                setAllCourseData(courseData);
                setCoursesLoading(false);
            });

        customFetch(coursesUrl + CURRENT, {})
            .then((res) => res.json())
            .then((courseData) => {
                setCurrentCourseData(courseData);
            });

        customFetch(professorsUrl + ALL, {})
            .then((res) => res.json())
            .then((professorData) => {
                setAllProfessorData(professorData);
                setProfessorsLoading(false);
            });

        customFetch(professorsUrl + CURRENT, {})
            .then((res) => res.json())
            .then((professorData) => {
                setCurrentProfessorData(professorData);
            });

        customFetch(departmentUrl, {})
            .then((res) => res.json())
            .then((department) => {
                setDepartment(department);
                setTitleLoading(false);
                if (!department.departmentName) {
                    setDepartmentNotFound(true);
                }
            });

        customFetch(deparmentTitleStatsUrl, {})
            .then((res) => res.json())
            .then((departmentTitleStats) => {
                setDepartmentOverallRating(departmentTitleStats.overallRating);
                setDepartmentPercentRecommended(
                    Math.round(departmentTitleStats.wouldRecommend * 100)
                );
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleFilterProfessors(newValue) {
        setFilterProfessors(newValue);
    }

    function handleFilterCourses(newValue) {
        setFilterCourses(newValue);
    }

    return (
        <>
            {departmentNotFound ? (
                <PageNotFound type={"Department"} />
            ) : (
                <div className="DepartmentPage">
                    <div className="departmentTopSection">
                        {titleLoading ? (
                            <CustomLinearProgress
                                minHeight={40}
                                minWidth={90}
                                minMobileHeight={45}
                                minMobileWidth={85}
                            />
                        ) : (
                            <p className="abbr">
                                {department.departmentAbbreviation}
                            </p>
                        )}
                        {titleLoading ? (
                            <CustomLinearProgress
                                minHeight={60}
                                minWidth={350}
                                minMobileHeight={45}
                                minMobileWidth={250}
                            />
                        ) : (
                            <p className="departmentTitleName">
                                {department.departmentName}
                            </p>
                        )}
                        <OverallStats
                            urlEnding={
                                "/overallDepartmentStats/" +
                                departmentId.toString()
                            }
                        />
                        <ReviewPageTitleStats
                            overallRating={departmentOverallRating}
                            wouldRecommendPercentage={
                                departmentPercentRecommended
                            }
                            objectId={""}
                        />
                    </div>

                    <div className="desktopBottom">
                        {professorsColumn}
                        {coursesColumn}
                        <ReviewStats
                            className="stats"
                            pid="department"
                            cid={departmentId}
                        />
                    </div>

                    <div className="midSizeBottom">
                        <div className="midSizeBottom-left">
                            <Tabs
                                className="midSizeDepartmentTabs"
                                value={midSizeTabValue}
                                onChange={handleMidSizeChange}
                                indicatorColor="secondary"
                            >
                                <Tab
                                    disableRipple
                                    label="Professors"
                                    value="professors"
                                />
                                <Tab
                                    disableRipple
                                    label="Courses"
                                    value="courses"
                                />
                            </Tabs>
                            {midSizeTabValue === "professors" && (
                                <>{professorsColumn}</>
                            )}
                            {midSizeTabValue === "courses" && (
                                <>{coursesColumn}</>
                            )}
                        </div>

                        <ReviewStats
                            className="stats"
                            pid="department"
                            cid={departmentId}
                        />
                    </div>

                    <div className="mobileBottom">
                        <Tabs
                            className="mobileDepartmentTabs"
                            value={mobileTabValue}
                            onChange={handleChange}
                            indicatorColor="secondary"
                        >
                            <Tab disableRipple label="Stats" value="stats" />
                            <Tab
                                disableRipple
                                label="Professors"
                                value="professors"
                            />
                            <Tab
                                disableRipple
                                label="Courses"
                                value="courses"
                            />
                        </Tabs>

                        {mobileTabValue === "stats" && (
                            <ReviewStats
                                className="stats"
                                pid="department"
                                cid={departmentId}
                            />
                        )}

                        {mobileTabValue === "professors" && (
                            <>{professorsColumn}</>
                        )}

                        {mobileTabValue === "courses" && <>{coursesColumn}</>}
                    </div>
                </div>
            )}
        </>
    );
}
export default DepartmentHomePage;
