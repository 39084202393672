import { useState, useEffect } from "react";
import "./StatsList.css";
import { Link } from "react-router-dom";
import CustomLinearProgress from "../CustomComponents/CustomLinearProgress";

function RankingList ( {rankBy} ) { // rankBy should be gradYear or major

	const [rankings, setRankings] = useState([]);
	const [loadingRankings, setLoadingRankings] = useState(true);

	useEffect (() => {
		fetch ('/reviewRankings/' + rankBy).then(
			res => res.json()
		).then (
			data =>  {
				setRankings(data);
				setLoadingRankings(false);
			}
		);
	// eslint-disable-next-line
	}, []);

	return (
		<div className="statsList">
			{loadingRankings ?
				<div style={{marginLeft: "15px"}}>
					<CustomLinearProgress lightMode={true} minWidth={400} minHeight={467} />
				</div>
				:
				<div className="statsListItems">
					{rankings.map((item, index) => (
						<Link className="statsListItem" key={index}>
							<div className="statsListItemLeft">
								<p className="statsListNumber">{index+1}</p>
								{rankBy === 'gradYear' && <p>Class of {item[0]}</p>}
								{rankBy === 'major' && <p className="black-text" to={"/department/" + item[2]}>{item[0]}</p>}
							</div>
							
							<p className="rankingListValue">{item[1]} reviews</p>
						</Link>
					))}
				</div>
			}
		</div>
	);
}

export default RankingList ;