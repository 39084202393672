import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./ReviewStats.css";
import "../../util/colors.css";
import CustomLinearProgress from "../CustomComponents/CustomLinearProgress";
import {
    findColorForRecommendPercentage,
    findReviewStatsColor,
} from "../../util/randomMethods";
import {
    defaultReviewStats,
    reviewStatsPropNames,
} from "../../util/randomConstants";
import {
    GET_REVIEW_INFO_BY_DEPARTMENT,
    GET_REVIEW_INFO_BY_PROFESSOR,
} from "../../util/apiRoutes";
import { customFetch } from "../../util/customFetch";
import { GET_PROFESSOR, GET_USER } from "../../util/apiRoutes";

function ReviewStats({ cid, pid }) {

    const [reviewData, setReviewData] = useState(defaultReviewStats);
    const [loadingData, setLoadingData] = useState(true);
    const [noReviews, setNoReviews] = useState(0); // 0 = loading, 1 = no reviews, 2 = reviews
    const [professorInfo, setProfessorInfo] = useState({fullName: "All Professors"});
    const [courseInfo, setCourseInfo] = useState({courseName: "All Courses"});

    let reviewDataUrl;

    if (pid === "department") {
        reviewDataUrl = GET_REVIEW_INFO_BY_DEPARTMENT + cid.toString();
    } else {
        reviewDataUrl =
            GET_REVIEW_INFO_BY_PROFESSOR +
            pid.toString() +
            "/course/" +
            cid.toString();
    }

    const location = useLocation()

    useEffect(() => {
        setLoadingData(true);

        let professorUrl = GET_PROFESSOR + pid.toString(); // getting professor name
        let courseUrl = GET_USER + cid.toString(); // getting course title


        customFetch(professorUrl, {})
            .then((res) => res.json())
            .then((professor) => {
                if (pid === 0) {
                    setProfessorInfo({fullName: "All Professors"})
                } else {
                    setProfessorInfo({fullName: professor.fullName})
                }
            });

        customFetch(courseUrl, {})
        .then((res) => res.json())
        .then((course) => {
            console.log(course)
            if (cid === 0) {
                setCourseInfo({courseName: "All Courses"})
            } else {
                setCourseInfo({courseName: course.courseName})
            }
        });
        

        console.log(cid)

        customFetch(reviewDataUrl, {})
            .then((res) => res.json())
            .then((reviewData) => {
                setReviewData(reviewData);
                setLoadingData(false);

                if (reviewData === "no reviews") {
                    setNoReviews(1);
                } else {
                    setNoReviews(2);
                }
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cid, pid]);

    let props;
    let recommendPercentage;
    let propText;

    // ONLY CREATE THESE VARIABLES IF THERE ARE REVIEWS
    if (noReviews === 2) {
        recommendPercentage = Math.round(reviewData.wouldRecommend * 100);

        props = [
            reviewData.overallRating,
            recommendPercentage,
            reviewData.testDifficulty,
            reviewData.workloadQuantity,
            reviewData.gradingDifficulty,
            reviewData.materialInteresting,
            reviewData.professorRating,
        ].map((elem) => elem.toFixed(1)); // rounding review data to one decimal place

        // prop words (Medium, Great, etc.)
        propText = [
            props[0],
            recommendPercentage.toString(),
            reviewData.testDifficultyText,
            reviewData.workloadQuantityText,
            reviewData.gradingDifficultyText,
            reviewData.materialInterestingText,
            reviewData.professorRatingText,
        ];
    }

    return (
        <>
            {loadingData && (
                <CustomLinearProgress
                    lightMode={true}
                    minHeight={350}
                    minWidth={350}
                    minMobileHeight={350}
                    minMobileWidth={350}
                />
            )}
            {!loadingData && reviewData.overallRating && noReviews === 2 && (
                <div className="reviewData white-box">
                    <div>
                        {location.pathname.includes('course') && <p className="statsBoxTitle">{professorInfo.fullName}</p>}
                        {location.pathname.includes('prof') &&<p className="statsBoxTitle">{courseInfo.courseName}</p>}
                        {location.pathname.includes('department') &&<p className="statsBoxTitle">All Courses & Professors</p>}
                    </div>
                    <div className="statsBoxQualities"> 
                        {propText.map((prop, index) => {
                            let colorClass = "";
                            if (index === 1) {
                                colorClass = findColorForRecommendPercentage(
                                    props[index]
                                );
                            } else {
                                colorClass = findReviewStatsColor(props[index]);
                            }

                            return (
                                <div
                                    key={reviewStatsPropNames[index]}
                                    className={"reviewStatsLine " + colorClass}
                                >
                                    <p className="propName">
                                        {reviewStatsPropNames[index]}{" "}
                                    </p>
                                    <div className={colorClass}>
                                        <p
                                            className={
                                                "reviewStatsText " +
                                                colorClass +
                                                "-text"
                                            }
                                        >
                                            {prop}
                                            {index === 1 ? "%" : ""}{" "}
                                            {index > 1
                                                ? "(" + props[index] + ")"
                                                : ""}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
}

export default ReviewStats;
