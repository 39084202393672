import { FaChalkboardTeacher, FaBook, FaPencilAlt } from "react-icons/fa";
import CustomLinearProgress from "../CustomComponents/CustomLinearProgress";
import { useEffect, useState } from "react";
import { customFetch } from "../../util/customFetch";

function OverallStats({ urlEnding }) {

    const [overallStats, setOverallStats] = useState({});
    const [loadingOverallStats, setLoadingOverallStats] = useState(true);

    useEffect(() => {
        // fetch overall stats (total courses, professors, reviews)
        customFetch(urlEnding, {})
            .then((response) => response.json())
            .then((data) => {
                setOverallStats(data);
                setLoadingOverallStats(false);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const overallStatsLoading = (
        <CustomLinearProgress
            minWidth={90}
            minHeight={20}
            minMobileWidth={70}
        />
    );

    return (
        <div className="overallStats">
            <div className="overallStats-item">
                <FaChalkboardTeacher className="statspage-faicon" />
                {loadingOverallStats ? (
                    <>{overallStatsLoading}</>
                ) : (
                    <p>
                        {overallStats["professors"]?.toLocaleString("en-US")}{" "}
                        professors
                    </p>
                )}
            </div>
            <div className="overallStats-item">
                <FaBook className="statspage-faicon" />
                {loadingOverallStats ? (
                    <>{overallStatsLoading}</>
                ) : (
                    <p>
                        {overallStats["courses"]?.toLocaleString("en-US")}{" "}
                        courses
                    </p>
                )}
            </div>
            <div className="overallStats-item">
                <FaPencilAlt className="statspage-faicon" />
                {loadingOverallStats ? (
                    <>{overallStatsLoading}</>
                ) : (
                    <p>
                        {overallStats["reviews"]?.toLocaleString("en-US")}{" "}
                        reviews
                    </p>
                )}
            </div>
        </div>
    );
}

export default OverallStats;
