import "./CustomComponents.css"
import { Select } from "@mui/material";

function CustomSelect({
	multiple, value, widthPercent, height, minWidth,
	maxWidth, options, handleChange, menuItems,
	borderRadius, additionalStyles, isNavBar
}) {

	widthPercent = widthPercent || "100%";
	height = height || "40px";
	borderRadius = borderRadius || "10px";
	minWidth = minWidth || "none";
	maxWidth = maxWidth || "none";
	
	const screenWidth = window.innerWidth;
	let marginTop = (isNavBar && screenWidth > 600) ? "10px" : "5px";

	return (
		<Select
			className= "custom-select"
			multiple={multiple}
			value={value}
			MenuProps={{
				anchorOrigin: {
					vertical: "bottom",
					horizontal: "left"
				},
				transformOrigin: {
					vertical: "top",
					horizontal: "left",
				},
				getcontentanchorel: null,
				PaperProps: {
					style: {
						marginTop: marginTop,
					}
				}
			}}
			style={{
				border: "1px solid black", color: "black", fill:"white",
				backgroundColor: "white", width: widthPercent, minWidth: minWidth, maxWidth: maxWidth,
				borderRadius: borderRadius, height: height, padding: 0, marginLeft: "0rem", 
				marginTop: "0rem", ...additionalStyles
				}}
			sx={{
				"& .MuiOutlinedInput-root": {
					borderRadius: "10px",
					padding: "0",
					border: "1px solid black",
					height: height,
					minWidth: minWidth,
					paddingLeft: "10px"
				},
				'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
					border: "1px solid black",
					outline: 'none !important',
				},
				'.MuiSvgIcon-root ': {
					fill: "black !important",
				},
				"& button.MuiButtonBase-root" : {
					visibility: "visible",
				},
				"& .MuiPaper-root": {
					maxWidth: widthPercent,
					marginTop: "15px",
				},
				"& .MuiMenuItem-root": {
					minHeight: "unset !important",
				},
			}}
			options={options}
			onChange={handleChange}
		>
			{menuItems}
		</Select>
	);
}

export default CustomSelect;