import LinearProgress from '@mui/material/LinearProgress';
import { useRef } from 'react';

function CustomLinearProgress({lightMode, minWidth, minHeight, minMobileWidth, minMobileHeight}) {
	const mobile = useRef(window.innerWidth);

	if (!minWidth) {
		minWidth = 40;
	}
	if (!minHeight) {
		minHeight = 25;
	}
	if (!minMobileWidth) {
		minMobileWidth = "100%";
	}
	if (!minMobileHeight) {
		minMobileHeight = minHeight;
	}

	const normalStyle = {
		minWidth: minWidth,
		minHeight: minHeight,
		borderRadius: "15px",
		maxWidth: "100%"
	  };

	const mobileStyle = {
		borderRadius: "15px",
		minHeight: minMobileHeight,
		minWidth: minMobileWidth,
		maxWidth: "100%"
	}

	const darkModeBackground = 'linear-gradient(to right, rgb(31, 31, 31), rgb(24, 24, 24))'
	const darkModeProgressBarBackground = 'linear-gradient(120deg, rgba(27,27,27, 1), rgba(50,50,50,0.5), rgba(27,27,27, 1))'

	const lightModeBackground = 'linear-gradient(to right, rgb(230, 230, 230), rgb(210, 210, 210))'
	const lightModeProgressBarBackground = 'linear-gradient(120deg, rgba(230,230,230, 1), rgba(200,200,200,0.5), rgba(230,230,230, 1))'

  return (
	<div className="titleLoading loading-div" 
		style={mobile.current < 800 ? mobileStyle : normalStyle}
	>
		<LinearProgress
		sx={{
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			borderRadius: 'inherit',
			background: lightMode ? lightModeBackground : darkModeBackground,
			'& .MuiLinearProgress-bar': {
				background: lightMode ? lightModeProgressBarBackground : darkModeProgressBarBackground,
				// background: 'linear-gradient(120deg, rgb(27, 27, 27), rgba(0,0,0,0.5), rgb(27,27,27))',
				animationDuration: '3s',
			},
		}}
		style={{height: "100%"}}
		/>
	</div>
  );
}

export default CustomLinearProgress;