import React, { useEffect, useState } from "react";
import CustomLinearProgress from "../../CustomComponents/CustomLinearProgress";
import "./ReviewPageTitle.css";
import { useParams } from "react-router-dom";
import CopyToClipboardLink from "../../Other/CopyToClipboard";
import { FaRegStar, FaStar } from "react-icons/fa";
import ReviewPageTitleStats from "./ReviewPageTitleStats";
import { COURSE_DEPARTMENTS_AND_NUMBERS, COURSE_TITLE_STATS, IS_COURSE_STARRED, STAR_OR_UNSTAR_COURSE } from "../../../util/apiRoutes";
import { customFetch } from "../../../util/customFetch";

function CourseTitle({course}) {

    const [courseOverallRating, setCourseOverallRating] = useState(-1);
    const [courseWouldRecommend, setCourseWouldRecommend] = useState(-1);
	const [courseDepartmentsAndNumbers, setCourseDepartmentsAndNumbers] = useState([]);

	const [departmentLoading, setDepartmentLoading] = useState(true); // loading for departments
    const [courseLoading, setCourseLoading] = useState(true); // loading for course
    const [isStarred, setIsStarred] = useState();
    const [noDescription, setNoDescription] = useState(false);

	const { courseId } = useParams();

    let departmentsUrl = COURSE_DEPARTMENTS_AND_NUMBERS + courseId.toString(); // getting all departments for a course
    let titleStatsUrl = COURSE_TITLE_STATS + courseId.toString(); // getting title stats for a course

    let starredCourseUrl = STAR_OR_UNSTAR_COURSE + courseId.toString();
    let isCourseStarredUrl = IS_COURSE_STARRED + courseId.toString();

	useEffect(() => {
		// course departments
        customFetch(departmentsUrl, {})
            .then((res) => res.json())
            .then((courseDepartments) => {
                setCourseDepartmentsAndNumbers(courseDepartments);
                setDepartmentLoading(false);
            });

        customFetch(titleStatsUrl, {})
            .then((res) => res.json())
            .then((titleStats) => {
                setCourseOverallRating(titleStats.overallRating);
                setCourseWouldRecommend(Math.round(titleStats.wouldRecommend * 100));
            });
            
        // check if the course is starred by the user
        customFetch(isCourseStarredUrl, {})
            .then((res) => res.json())
            .then((star) => {      
                setIsStarred(star.result);
            })

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    useEffect(() => {
        if (course.courseName) {
            setCourseLoading(false); 
        }
        if (course.description === "") {
            setNoDescription(true);
        }
    }, [course]);

    const handleStar = (() => {
        setIsStarred(!isStarred);
        
        customFetch(starredCourseUrl, {
            method: "POST",
            body: JSON.stringify({
                courseId: courseId,
            }), 
            headers: {
				'content-type': 'application/json',
				'Access-Control-Allow-Headers': 'content-type'
			}
        })
    })

    const overallClassName = "review-page-title" + 
        (noDescription ? " title-no-description" : "");

    return (
        <div className={overallClassName}>
            <div className="title-top-row">
                <div className="abbreviations">
                    {departmentLoading ?
                        <CustomLinearProgress
                            minHeight={50}
                            minWidth={115}
                            minMobileHeight={50}
                            minMobileWidth={115}
                        />
                        :
                        <>  
                            {courseDepartmentsAndNumbers.map((department) => (
                                <div
                                    key={department.departmentAbbreviation}
                                    className="abbr"
                                >
                                    {department.departmentAbbreviation + " " + department.courseNumber}
                                </div>
                            ))}
                        </>
                    }
                </div>

                <div className="title-bottom-row">
                    {courseLoading ?
                        <CustomLinearProgress
                            minHeight={80}
                            minWidth={600}
                            minMobmileHeight={70}
                            minMobileWidth={"calc(100vw - 2rem)"}
                        />
                        :
                        <>
                            <p className="courseTitle">{course.courseName}</p>
                            <CopyToClipboardLink text={course.courseName} />
                            {isStarred ? 
                                <FaStar className="star-icon" onClick={handleStar} ></FaStar>
                                :
                                <FaRegStar className="star-icon" onClick={handleStar} ></FaRegStar>
                            }
                        </>
                    }
                    
                </div>
                
            </div>
            <div className={noDescription ? "description no-description" : "description existing-description"}>
                {courseLoading ?
                    <CustomLinearProgress
                        minHeight={80}
                        minWidth={600}
                        minMobileHeight={150}
                        minMobileWidth={"calc(100vw - 40px)"}
                    />
                    :
                    <p>{course.description}</p>
                }
            </div>
            <ReviewPageTitleStats
                onCoursePage={true}
                overallRating={courseOverallRating}
                wouldRecommendPercentage={courseWouldRecommend}
                objectId={courseId}
            />
        </div>
    );
}

export default CourseTitle;
