import "./AboutPage.css";
// import { Link } from "react-router-dom";


function AboutPage() {
	return (
		<div className="aboutPage gray-box">
			<h1 className="about-page-title">
				About This Website
			</h1>
			<p className="aboutText">
				This website helps students make informed decisions about class 
				registration by providing them with other students' opinions about courses and professors. 
				The website was built and is run by students, not the Bowdoin administration. For any 
				questions or comments, please {' '}
				{/* use the {' '}
				<Link to={'/contact'} className="inline-link standard-link" >Contact Form</Link>
				{' '} or  */}
				email us at <br/> <strong>course-reviews@bowdoin.edu</strong>. 
			</p>
			<p className="aboutText">
				Bowdoin Course Reviews depends on students who make an effort to share their experiences. 
				Please contribute thoughtful and honest reviews of courses you have previously taken.
				Lying and defamation will not be tolerated, so please only submit comments that are accurate and
				considerate. If you had a bad experience with a course or professor, please provide
				constructive feedback but be respectful. All reviews are published anonymously, 
				but usernames are recorded. We will remove any inappropriate reviews and will 
				take action if a student submits multiple that we have to take down.
			</p>
			<p className="aboutText">
				Lastly, we'd like to say a big thank you to Professor Stephen Houser and Erik Pearson for all
				of their help and to the BSG for their support; we couldn't have done it without them.
			</p>
			<p className="aboutText">
				We hope you find Bowdoin Course Reviews helpful!
			</p>
		</div>
	);
}

export default AboutPage;