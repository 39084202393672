
export const USER_REVIEWS = '/reviews/user';
export const USER_STARRED_COURSES = '/getAllStarredCourses/user';
export const ALL_DEPARTMENTS = '/allDepartments';
export const ADD_COURSE = '/addCourse';
export const GET_ATTRIBUTE_DICTS = "/getAttrDicts";
export const GET_DEPARTMENT = "/getDepartment/";
export const COURSES_BY_DEPARTMENT = "/courses/department/";
export const PROFESSORS_BY_DEPARTMENT = "/professors/department/";
export const DEPARTMENT_TITLE_STATS = "/titleStats/department/";
export const ALL_COURSES = "/allCourses";
export const ALL_PROFESSORS = "/allProfessors";
export const UPDATE_USER = "/updateUser";
export const IS_COURSE_STARRED = "/isCourseStarred/course/";
export const STAR_OR_UNSTAR_COURSE = "/starOrUnstarCourse/course/";
export const GET_USER_BY_ID = "/getUserById";
export const GET_USER = "/getCourse/";
export const GET_REVIEW_INFO_BY_DEPARTMENT = "/reviewInfo/department/";
export const GET_REVIEW_INFO_BY_PROFESSOR = "/reviewInfo/professor/";
export const GET_PROFESSOR = "/getProfessor/";
export const COURSE_PROFESSORS = "/courseProfs/";
export const PROFESSOR_COURSES = "/profCourses/";
export const COURSE = "/course/";
export const REVIEWS = "/reviews/";
export const PROF = "/prof/";
export const LIMIT = "/limit/";
export const PROFESSOR_DEPARTMENTS = "/professorDepartments/";
export const PROFESSOR_TITLE_STATS = "/titleStats/professor/";
export const COURSE_TITLE_STATS = "/titleStats/professor/0/course/";
export const COURSE_DEPARTMENTS_AND_NUMBERS = "/courseDepartmentsAndNumbers/";
export const REVIEW_COUNT_BY_PROFESSOR = "/reviewCount/type/professor/id/";
export const REVIEW_COUNT_BY_COURSE = "/reviewCount/type/course/id/";
export const ALL = "/all";
export const CURRENT = "/current";

