import "./PageNotFound.css";
import { Link } from "react-router-dom";

function PageNotFound({ pageType }) {
    return (
        <div className="notFound">
            <h1 className="title404">404: {pageType} Not Found</h1>
            <Link className="whiteButton" to="/">
                Return home
            </Link>
        </div>
    );
}

export default PageNotFound;
