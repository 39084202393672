import { useLocation } from "react-router-dom";
import AddReviewButton from "./AddReviewButton.js";
import { Navbar as NavBarElement } from "react-bootstrap";
import HamburgerMenu from "./HamburgerMenu.js";
import "./NavBar.css";
import NavBarSearch from "./NavBarSearch.js";
import { specialNavbarRoutes } from "../../util/randomConstants.js";

function Navbar({ handleLogout }) {
    const location = useLocation();


    return (
        <NavBarElement className="navbar" bg="dark" variant="dark">
            {!specialNavbarRoutes.includes(location.pathname) && ( // normal navbar
                <div className="otherNav">
                    <div className="top-row-navbar">
                        <NavBarElement.Brand className="nav nav-fulltext" href="/">
                            Bowdoin Course Reviews
                        </NavBarElement.Brand>
                        <NavBarElement.Brand className="nav nav-smalltext" href="/">
                            BCR
                        </NavBarElement.Brand>
                        <NavBarSearch/>
                        <div className="rightSide">
                            <AddReviewButton />
                            <HamburgerMenu handleLogout={handleLogout} />
                        </div>
                    </div>
                    <div className="bottom-row-navbar">
                        <NavBarSearch />
                    </div>
                </div>
            )}

            {location.pathname === "/" && ( // home page navbar
                <div className="homeNav">
                    <div className="rightSide">
                        <AddReviewButton />
                        <HamburgerMenu handleLogout={handleLogout} />
                    </div>
                </div>
            )}

            {location.pathname === "/addreview" && ( // create review page navbar
                <div className="otherNav">
                    <div className="top-row-navbar">
                        <NavBarElement.Brand className="nav nav-fulltext" href="/">
                            Bowdoin Course Reviews
                        </NavBarElement.Brand>
                        <NavBarElement.Brand className="nav nav-smalltext" href="/">
                            BCR
                        </NavBarElement.Brand>
                        <NavBarSearch />
                        <HamburgerMenu handleLogout={handleLogout} />
                    </div>
                    <div className="bottom-row-navbar">
                        <NavBarSearch />
                    </div>
                </div>
            )}

            
        </NavBarElement>
    );
}

export default Navbar;
