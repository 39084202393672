import { BASE_URL } from "../components/config";

function updateOptions(options) {
    const update = {
        ...options,
        credentials: 'include',
        withCredentials: true,
      };
    return update;
}

export function customFetch(route, options) {
    const url = BASE_URL + route;
    const updatedOptions = updateOptions(options);
    return fetch(url, updatedOptions);
}
