// import RatingButtons from "../AddReview/RatingButtons";
// import { useState } from "react";
// import { useNavigate } from "react-router-dom";
import "./Contact.css";
// import { contactUsRatingWords } from "../../util/randomConstants";
// import { customFetch } from "../../util/customFetch";

function Contact() {

    // const [email, setEmail] = useState("");
    // const [rating, setRating] = useState(0);
    // const [comments, setComments] = useState("");
    // const [message, setMessage] = useState("");

    // const navigate = useNavigate();

    // const handleSubmit = (event) => {
    //     event.preventDefault();

    //     customFetch("/postComment",
    //         {
    //             method: "POST",
    //             body: JSON.stringify({
    //                 email: email,
    //                 rating: rating,
    //                 comments: comments,
    //             }),
    //             headers: {
    //                 "content-type": "application/json",
    //                 "Access-Control-Allow-Headers": "content-type",
    //             },
    //         })
    //         .then((response) => response.json())
    //         .then((response) => {
    //             setMessage(response.message);

    //             if (response.success) {
    //                 setEmail("");
    //                 setRating(0);
    //                 setComments("");
    //                 setTimeout(() => {
    //                     navigate("/");
    //                 }, 1000);
    //             }
    //         })
    //         .catch((error) => {
    //             setMessage(error.message);
    //         });
    // };

    return (
        <div className="contactUs">
            <form className="contactUsForm gray-box">
                 {/* </div>onSubmit={handleSubmit}> */}
                <div className="header">
                    <h1 className="contactus-title">Contact Us</h1>
                    <p className="disclaimer">
                        Have a question, comment, concern, or an idea for a feature we should add?
                        Please send us an email at {' '}
                        <strong>course-reviews@bowdoin.edu</strong>.
                    </p>
                </div>
                {/* <div className="contactUsQuestions">
                    <div>
                        <label className="questionTitleContact">
                            Your Email
                        </label>
                        <input
                            className="emailInput info-input black-background-input"
                            type="text"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </div>
                    <div>
                        <label className="questionTitle">
                            {" "}
                            Are you happy with the site?{" "}
                        </label>
                        <RatingButtons
                            value={rating}
                            onChange={setRating}
                            words={contactUsRatingWords}
                        />
                    </div>
                    <div className="comment">
                        <div className="commentTitleLine">
                            <p className="commentTitle">Comments</p>
                            <p className="commentLength">
                                {comments.length}/1500 characters
                            </p>
                        </div>
                        <textarea
                            className="commentBox black-background-input"
                            placeholder="Enter your comments here"
                            value={comments}
                            onChange={(event) =>
                                setComments(event.target.value)
                            }
                            maxLength="1500"
                        ></textarea>
                    </div>
                </div>
                <div className="bottomLine">
                    <input
                        className="whiteButton"
                        type="submit"
                        value="Submit"
                    />
                    <p>{message}</p>
                </div> */}
            </form>
        </div>
    );
}

export default Contact;
