import React from "react";
import CourseReviewPage from "./ReviewPage/CourseReviewPage";
import {
    Route,
    Routes
} from "react-router-dom";
import HomePage from "./HomePage/HomePage";
import CreateCourse from "./AddCourse/CreateCourse";
import ProfessorReviewPage from "./ReviewPage/ProfessorReviewPage";
import "./App.css";
import DepartmentHomePage from "./DepartmentPage/DepartmentHomePage";
import CreateReview from "./AddReview/CreateReview";
import CreateProfessor from "./AddProfessor/CreateProfessor";
import NavBar from "./NavBar/NavBar";
import SignInPage from "./SignIn/SignInPage";
import PrivateRoute from "./SignIn/PrivateRoute";
import NotFound from "./Other/NotFound";
import ProfilePage from "./Other/ProfilePage";
import AboutPage from "./Other/AboutPage";
import Contact from "./Other/Contact";
import Footer from "./Footer/Footer";
import StatsPage from "./StatsPage/StatsPage";
import { LoginCallback } from "@okta/okta-react";

function App() {

    // LOGOUT
    const handleLogout = () => {
        localStorage.removeItem("okta-token-storage");
        localStorage.removeItem("id");
    };

    return (
        <>
            <NavBar handleLogout={handleLogout} />
            <Routes>
                <Route path="/login/callback" element={<LoginCallback/>}/>
                <Route path="/login" element={<SignInPage />} />
                <Route
                    path="/"
                    element={<PrivateRoute component={HomePage} />}
                />

                <Route
                    path="/reviews/course/:courseId"
                    element={<PrivateRoute component={CourseReviewPage} />}
                />
                <Route
                    path="/reviews/prof/:profId"
                    element={<PrivateRoute component={ProfessorReviewPage} />}
                />
                <Route
                    path="/department/:departmentId"
                    element={<PrivateRoute component={DepartmentHomePage} />}
                />

                <Route
                    path="/addcourse"
                    element={<PrivateRoute component={CreateCourse} />}
                />
                <Route
                    path="/addreview"
                    element={<PrivateRoute component={CreateReview} />}
                />
                <Route
                    path="/addprofessor"
                    element={<PrivateRoute component={CreateProfessor} />}
                />

                <Route
                    path="/profile"
                    element={<PrivateRoute component={ProfilePage} />}
                />
                <Route
                    path="/about"
                    element={<PrivateRoute component={AboutPage} />}
                />
                <Route
                    path="/contact"
                    element={<PrivateRoute component={Contact} />}
                />
                <Route
                    path="/stats/"
                    element={<PrivateRoute component={StatsPage} />}
                />

                <Route
                    path="*"
                    element={<PrivateRoute component={NotFound} />}
                />
            </Routes>
            <Footer />
        </>
    );
}

export default App;