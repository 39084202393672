const colorClassNames = ["green-quality", "lightgreen-quality", "yellow-quality", "orange-quality", "red-quality"];
const orangeProps = ["Lots", "Moderately hard", "A little", "Poor", 2];
const yellowProps = ["Average", "Medium", "Moderately", 3];
const lightgreenProps = ["Moderately easy", "Little", "Very", "Good", 4];
const greenProps = ["Extremely", "Very little", "Easy", "Excellent", 5];

export function findColorForRecommendPercentage(prop) {
	if (prop >= 90) {
		return colorClassNames[0];
	} else if (prop >= 70) {
		return colorClassNames[1];
	} else if (prop >= 50) {
		return colorClassNames[2];
	} else if (prop >= 30) {
		return colorClassNames[3];
	} else {
		return colorClassNames[4];
	}
}

export function findReviewStatsColor(prop) {
	if (prop >= 4.4) {
		return colorClassNames[0];
	} else if (prop >= 3.7) {
		return colorClassNames[1];
	} else if (prop >= 2.8) {
		return colorClassNames[2];
	} else if (prop >= 2) {
		return colorClassNames[3];
	} else {
		return colorClassNames[4];
	}
}

export function convertPropWordToColor(prop) {
	if (greenProps.includes(prop)) {
		return colorClassNames[0];
	} else if (lightgreenProps.includes(prop)) {
		return colorClassNames[1];
	} else if (yellowProps.includes(prop)) {
		return colorClassNames[2];
	} else if (orangeProps.includes(prop)) {
		return colorClassNames[3];
	} else {
		return colorClassNames[4];
	}
}