
export const sortByTextDict = {
	'overallRating': ['High to Low', 'Low to High'],
	'testDifficulty': ['Easy to Hard', 'Hard to Easy'],
	'workloadQuantity': ['Low to High', 'High to Low'],
	'gradingDifficulty': ['Easy to Hard', 'Hard to Easy'],
	'materialInteresting': ['High to Low', 'Low to High'],
	'professorRating': ['High to Low', 'Low to High'],
	'wouldRecommend': ['High to Low', 'Low to High'],
}

export const sortAttributes = [
	{value: 'overallRating', label: 'Overall Rating'},
	{value: 'testDifficulty', label: 'Test Difficulty'},
	{value: 'workloadQuantity', label: 'Workload Quantity'},
	{value: 'gradingDifficulty', label: 'Grading Difficulty'},
	{value: 'materialInteresting', label: 'Material Interesting'},
	{value: 'professorRating', label: 'Professor Rating'},
	{value: 'wouldRecommend', label: 'Would Recommend'},
]

export const semesterOptions = [{label: "Fall", value: "Fall"}, {label: "Spring", value: "Spring"}];
export const wouldRecommendOptions = [{label: "Yes", value: 1}, {label: "No", value: 0}];

export const loadFooterRightAwayPages = ["/", "/addcourse", "/addreview", "/addprofessor", "/about", "/contact", "/profile", "*"];
export const specialNavbarRoutes = ["/", "/addreview", "/login", "/login/callback"];

export const navbarSelectOptions = [
	{ value: "course", label: "Courses" },
	{ value: "professor", label: "Professors" },
	{ value: "department", label: "Departments" },
];

export const contactUsRatingWords = {
	1: "Not at all",
	2: "A little",
	3: "It's okay",
	4: "Very",
	5: "It's awesome"
};

export const defaultUser = {
	username: "",
	gradYear: 0,
	major1: "",
	major2: "",
	minor: "",
	major1Text: "",
	major2Text: "",
	minorText: "",
};

export const defaultReviewStats = {
	overallRating: 0,
	testDifficulty: 0,
	workloadQuantity: 0,
	gradingDifficulty: 0,
	materialInteresting: 0,
	professorRating: 0,
	testDifficultyText: "",
	workloadQuantityText: "",
	gradingDifficultyText: "",
	materialInterestingText: "",
	professorRatingText: "",
};

export const reviewStatsPropNames = ["Overall Rating", "Would Recommend", "Test Difficulty", "Workload Quantity",
"Grading Difficulty", "Material Interesting", "Professor Rating"];

export const defaultRatingButton = {'1': '1', '2': '2', '3': '3', '4': '4', '5': '5'};

export const titleStatsColorNames = ['green', 'lightgreen', 'yellow', 'orange', 'red'];