import { defaultRatingButton } from '../../util/randomConstants';
import './RatingButtons.css';

function RatingButtons({ value, onChange, words }) {
	if (words === undefined) {
		words = defaultRatingButton; // default value while page loads
	}

	let wordsList = [words['1'], words['2'], words['3'], words['4'], words['5']];

	if (wordsList && window.innerWidth < 600) {
		wordsList = wordsList.map((word) => {
			if (typeof(word) === 'string') {
				return word.replace("Moderately", "Mod.")
			} else {
				return word;
			}
		});
	}

  return (
    <div className="buttonRow">
		<div className='buttonDiv'><button type="button" className={value === 1 ? 'selectedB sb1' : 'B b1'} onClick={() => onChange(1)}>{wordsList[0]}</button></div>
		<div className='buttonDiv'><button type="button" className={value === 2 ? 'selectedB sb2' : 'B b2'} onClick={() => onChange(2)}>{wordsList[1]}</button></div>
		<div className='buttonDiv'><button type="button" className={value === 3 ? 'selectedB sb3' : 'B b3'} onClick={() => onChange(3)}>{wordsList[2]}</button></div>
		<div className='buttonDiv'><button type="button" className={value === 4 ? 'selectedB sb4' : 'B b4'} onClick={() => onChange(4)}>{wordsList[3]}</button></div>
		<div className='buttonDiv'><button type="button" className={value === 5 ? 'selectedB sb5' : 'B b5'} onClick={() => onChange(5)}>{wordsList[4]}</button></div>
	</div>
  );
}

export default RatingButtons;
