import './HomePage.css';
import SearchBar from './SearchBar'; 
import { Tab, Tabs } from '@mui/material';
import { useState, useEffect } from 'react';
import { customFetch } from '../../util/customFetch';

function HomePage() {
	const [searchType, setSearchType] = useState("course");
	const handleChange = (event, newValue) => {
		setSearchType(newValue);
	}

	useEffect(() => {
        customFetch('/addSiteVisit', {})

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

	return (
		<div className="HomePage">
			<header className="HomePage-header">
				<p>Bowdoin College</p>
				<p>Course Reviews</p>
			</header>
			<Tabs
				className="searchTabs" 
				value={searchType} 
				onChange={handleChange} 
				indicatorColor="secondary"
			>
  				<Tab disableRipple className="search-tab" label="Courses" value="course"/>
  				<Tab disableRipple className="search-tab" label="Professors" value="professor"/>
				<Tab disableRipple className="search-tab" label="Departments" value="department"/>
			</Tabs>
			
			<SearchBar searchType={searchType}/>
    </div>
	);
}

export default HomePage;