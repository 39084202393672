import { useState, useEffect } from "react";
import { MenuItem } from "@mui/material";
import CustomSelect from "../CustomComponents/CustomSelect";
import "./StatsList.css";
import { Link } from "react-router-dom";
import { TableSortLabel } from '@mui/material';
import CustomLinearProgress from "../CustomComponents/CustomLinearProgress";
import { sortAttributes, sortByTextDict } from "../../util/randomConstants";

function StatsList ( {itemType} ) {

	const REPEATED_LOADING_BAR_COUNT = 10;

	const [loadingStats, setLoadingStats] = useState(true);

	const [stats, setStats] = useState({});
	const [sortDirection, setSortDirection] = useState('desc');
	
	const startSortAttribute = itemType === 'professor' ? 'professorRating' : 'overallRating';
	const [sortAttribute, setSortAttribute] = useState(startSortAttribute);

	const [selectedStatsData, setSelectedStatsData] = useState([]);

	const linkStart = itemType === 'professor' ? '/reviews/prof/' : itemType === 'course' ? '/reviews/course/' : '/department/';

	const [sortByText, setSortByText] = useState(sortByTextDict[startSortAttribute][0]);


	useEffect (() => {
		// fetch stats for a certain type of object: professor, course, or department
		fetch ('/typeStats/' + itemType).then(
			res => res.json()
		).then (
			data =>  {
				setStats(data)
				setSelectedStatsData(data[startSortAttribute][sortDirection])
				setLoadingStats(false);
			}
			
		);
	// eslint-disable-next-line
	}, []);

	// handle sorting by a different attribute
	const handleChange = (event) => {
        setSortAttribute(event.target.value);
		setSortDirection('desc');
		setSelectedStatsData(stats[event.target.value]['desc']);
		setSortByText(sortByTextDict[event.target.value][0]);
	};

	// handle sorting by ascending or descending
	const handleSortClick = () => {
		setSelectedStatsData(stats[sortAttribute][sortDirection === 'asc' ? 'desc' : 'asc']);
		setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortByText(sortByTextDict[sortAttribute][sortDirection === 'asc' ? 0 : 1]);
	};

	return (
		<div className="statsList">
			{/* Sorting */}
			<div className="statsListSortLine">
				<p className="statsListSortText">Sort</p>
				<CustomSelect 
					value={sortAttribute}
					minWidth="200px"
					height="30px"
					widthPercent="30%"
					handleChange={handleChange}
					options={sortAttributes}
					style={{fontSize:"15px"}}
					menuItems={
						sortAttributes.map(attr => (
							<MenuItem key={attr.value} value={attr.value}>{attr.label}</MenuItem>
						))
					}
				/>
				{itemType !== 'professor' &&
					<TableSortLabel
						className="sort-label"
						active={sortDirection !== null}
						direction={sortDirection}
						onClick={handleSortClick}
						style={{color: "black", transitionDuration: "1s !important"}}
					>
						{sortByText}
					</TableSortLabel>
				}
			</div>
			
			<div className="statsListItems">
				{loadingStats ?
					<>
						{Array.from({ length: REPEATED_LOADING_BAR_COUNT }).map((_, index) => (
							<CustomLinearProgress key={index} lightMode={true} minWidth={450} minHeight={45} minMobileHeight={45} />
						))}
					</>
					:
					<>
						{selectedStatsData.map((item, index) => (
							<Link 
								to={linkStart + item.id}
								className="statsListItem"
								key={index}
							>
								<div className="statsListItemLeft">
									<p className="statsListNumber">{index+1}</p>
									<p>
										{itemType === 'professor' ? item.fullName : itemType === 'course' ? item.courseName : item.departmentName}
									</p>
								</div>
								<div className="statsListItemRight">
									{sortAttribute !== 'reviewCount' && sortAttribute !== 'wouldRecommend' &&
										<>
											<p className="statListValue">{(Math.round(item[sortAttribute.replace(/(ASC|DESC)/, "")] * 10) / 10).toFixed(1)} / 5</p>
											<p className="statListReviewCount">{item['reviewCount']} reviews</p>
										</>
									}
									{sortAttribute === 'reviewCount' &&
										<>
											<p className="statListReviewCountLarge">{item['reviewCount']} reviews</p>
										</>
									}
									{sortAttribute === 'wouldRecommend' &&
										<>
											<p className="statListValue">{Math.round(item[sortAttribute.replace(/(ASC|DESC)/, "")] * 100).toFixed(0)}%</p>
											<p className="statListReviewCount">{item['reviewCount']} reviews</p>
										</>
									}
								</div>
							</Link>
						))}
					</>
				}
			</div>
		</div>
	);
}

export default StatsList ;