import React, { useState } from "react";
import IconButton from '@mui/material/IconButton';
import { FaRegCopy, FaCheck } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CopyToClipboardLink = ({ text }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    return (
		<div className='copy-to-clipboard'>
			<CopyToClipboard text={text} onCopy={handleCopy}>
				<IconButton>
					{copied ?
						<FaCheck className="check-icon" />
						:
						<FaRegCopy className="copy-to-clipboard-icon"/>
					}
				</IconButton>
			</CopyToClipboard>
		</div>
    );
};

export default CopyToClipboardLink;
