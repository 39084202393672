import React, { useEffect, useState } from "react";
import "./ReviewPage.css";
import { useParams } from "react-router-dom";
import ReviewStats from "./ReviewStats";
import PageNotFound from "../Other/PageNotFound";
import CourseTitle from "./ReviewPageTitles/CourseTitle";
import CourseReviews from "./ReviewPagePieces/CourseReviews";
import { GET_USER } from "../../util/apiRoutes";
import { customFetch } from "../../util/customFetch";

function CourseReviewPage() {
    const [selectedProfessor, setSelectedProfessor] = React.useState(0);

    const [courseInfo, setCourseInfo] = useState({});
    const [courseNotFound, setCourseNotFound] = useState(false);
    const { courseId } = useParams();
    let courseUrl = GET_USER + courseId.toString(); // getting course title

    useEffect(() => {
        // course data
        customFetch(courseUrl, {})
            .then((res) => res.json())
            .then((course) => {
                setCourseInfo(course);
                if (!course.courseName) {
                    setCourseNotFound(true);
                }
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {courseNotFound ? (
                <PageNotFound pageType="Course" />
            ) : (
                <div className="ReviewList">
                    <CourseTitle course={courseInfo} />
                    <div
                        id="reviewList-bottom-id"
                        className="reviewList-bottom"
                    >
                        <div className="left">
                            <CourseReviews
                                course={courseInfo}
                                parentSetProfessorSelected={
                                    setSelectedProfessor
                                }
                            />
                        </div>
                        <div className="right">
                            <ReviewStats
                                pid={selectedProfessor}
                                cid={courseId}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
export default CourseReviewPage;
