import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FaUser, FaComment, FaSignOutAlt, FaTrophy, FaInfoCircle } from "react-icons/fa";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import "./HamburgerMenu.css";

function HamburgerMenu() {
	let baseURLWithoutPath = window.location.protocol + "//" + window.location.hostname;
	if (baseURLWithoutPath.includes('localhost')) {
		baseURLWithoutPath += ":8080";
	} else {
		baseURLWithoutPath = "https://course-reviews.students.bowdoin.edu";
	}

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
	if (!anchorEl){
    	setAnchorEl(event.currentTarget);
		document.getElementById("bars").classList.add('closed');
	} else{
		setAnchorEl(null);
	}
  };

  const handleClose = () => {
	document.getElementById("bars").classList.remove('closed');
    setAnchorEl(null);
  };

  return (
    <div>
		<IconButton
			onClick={handleClick}
			style={{width: 40, height: 40, padding: 0}}
			disableRipple
		>
			<div className="hamburger-bars" id="bars">
				<p className="bar bar1"></p>
				<p className="bar bar2"></p>
				<p className="bar bar3"></p>
			</div>
		</IconButton >
		<Menu
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
			onClick={handleClose}
			className="hamburgerMenu"
			style={{marginTop: 15}}
		>
			<MenuItem className="menuRow" component={Link} to="/profile" disableRipple >
				<FaUser className="faIcon" style={{fontSize: 25, color:"black", transition:'none', outline:0, marginRight:10 }} />
				Profile
			</MenuItem>
			<MenuItem className="menuRow" component={Link} to="/stats" disableRipple >
				<FaTrophy className="faIcon" style={{fontSize: 25, color:"black", transition:'none', outline:0, marginRight:10 }} />
				Stats
			</MenuItem>
			<MenuItem className="menuRow" component={Link} to="/about" disableRipple >
				<FaInfoCircle className="faIcon" style={{fontSize: 25, color:"black", transition:'none', outline:0, marginRight:10 }} />
				About
			</MenuItem>
			<MenuItem className="menuRow" component={Link} to="/contact" disableRipple >
				<FaComment className="faIcon" style={{fontSize: 25, color:"black", transition:'none', outline:0, marginRight:10 }} />
				Contact Us
			</MenuItem>
			<MenuItem className="menuRow" component="a" href={baseURLWithoutPath + "/logout"} disableRipple >
				<FaSignOutAlt className="faIcon" style={{fontSize: 25, color:"black", transition:'none', outline:0, marginRight:10 }} />
				Logout
			</MenuItem>
		</Menu>
    </div>
  );
}

export default HamburgerMenu;