import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { customFetch } from "../../util/customFetch.js";

const PrivateRoute = ({ component: Component, ...rest }) => {
	const [loggedIn, setLoggedIn] = useState(false);
	const [useEffectCompleted, setUseEffectCompleted] = useState(false);

	useEffect(() => {
		customFetch("/checkLogin", {})
		.then((res) => res.json())
		.then((res) => {
			if (res.loggedIn || res.loggedIn === "true") {
				setLoggedIn(true);
			}
			setUseEffectCompleted(true);
		})
		.catch((err) => {
			console.log(err);
		});
	}, []);

	return (
		useEffectCompleted ? (
			loggedIn ?
				<Component {...rest} />
				:
				<Navigate to="/login" replace={true} />
		)
		: 
		(
			<div>hi</div>
		)
	)
};

export default PrivateRoute;