import React, { useEffect, useState } from "react";
import "./CreateCourse.css";
import MenuItem from "@mui/material/MenuItem";
import CustomSelect from "../CustomComponents/CustomSelect";
import { useNavigate } from "react-router-dom";
import { ADD_COURSE, ALL_DEPARTMENTS } from "../../util/apiRoutes";
import { customFetch } from "../../util/customFetch.js";

function CreateCourse() {
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [departments, setDepartments] = useState([]);
    const [message, setMessage] = useState("");
    const [description, setDescription] = useState("");

    const [allDepartments, setAllDepartments] = React.useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        // List of departments
        customFetch(ALL_DEPARTMENTS, {})
            .then((res) => res.json())
            .then((allDepartments) => {
                setAllDepartments(allDepartments);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Submit the course to the database
    const handleSubmit = (event) => {
        event.preventDefault();

        customFetch(ADD_COURSE,
            {
                method: "POST",
                body: JSON.stringify({
                    courseNumber: parseInt(number),
                    courseName: name,
                    departments: departments,
                    description: description,
                }),
                headers: {
                    "content-type": "application/json",
                    "Access-Control-Allow-Headers": "content-type",
                },
            })
            .then((response) => response.json())
            .then((response) => {
                setMessage(response.message);

                // Wait for a second before redirecting
                if (
                    response.message === "Course added successfully, thank you!"
                ) {
                    setTimeout(() => {
                        navigate("/");
                    }, 1000);
                }
            })
            .catch((error) => {
                setMessage(error.message);
            });
    };

    const handleChange = (event) => {
        setDepartments(event.target.value);
    };

    const handleNumberChange = (event) => {
        if (event.target.value.length <= 4) {
            setNumber(event.target.value);
        }
    };

    const departmentOptions = allDepartments.map((dep) => ({
        label: dep.departmentName,
        value: dep.id,
    }));

    return (
        <form onSubmit={handleSubmit} className="newClassForm">
            <h1 className="addACourseTitle">Add a Course</h1>
            <div className="courseDisclaimer">
                <em>
                    Please use the form below to submit a new course. Before you
                    do, make sure that the course doesn't already exist. Please
                    make sure to use the official course name and number from
                    Classfinder.
                </em>
            </div>
            <label className="createCourseQuestion">
                <p className="createCourseQuestionTitle">Course Name</p>
                <input
                    className="courseNameInput black-background-input"
                    type="string"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                />
            </label>
            <label className="createCourseQuestion">
                <p className="createCourseQuestionTitle">
                    Course Number (ex. 1101, 3000)
                </p>
                <input
                    className="courseNumberInput black-background-input"
                    type="number"
                    value={number}
                    onChange={handleNumberChange}
                />
            </label>
            <label className="createCourseQuestion">
                <p className="createCourseQuestionTitle">
                    Department(s) (select up to three):
                </p>
                <CustomSelect
                    multiple={true}
                    value={departments}
                    widthPercent="85%"
                    handleChange={handleChange}
                    options={departmentOptions}
                    menuItems={departmentOptions.map((dep) => (
                        <MenuItem
                            className="addCourseDepartment"
                            key={dep.value}
                            value={dep.value}
                            disabled={
                                departments.length >= 3 &&
                                !departments.includes(dep.value)
                            }
                        >
                            {dep.label}
                        </MenuItem>
                    ))}
                />
            </label>
            <div className="createCourseQuestion">
                <div className="commentTitleLine">
                    <p className="commentTitle">
                        Course description (copy from Classfinder)
                    </p>
                </div>
                <textarea
                    className="commentBox black-background-input"
                    placeholder="Enter the course description here"
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                ></textarea>
            </div>

            <div className="bottom-line">
                <input className="whiteButton whiteButton-submit" type="submit" value="Submit" />
                <p>{message}</p>
            </div>
        </form>
    );
}

export default CreateCourse;
