import React, { useEffect, useState } from "react";
import "./CreateReview.css";
import CustomSelect from "../CustomComponents/CustomSelect";
import MenuItem from "@mui/material/MenuItem";
import RatingButtons from "./RatingButtons.js";
import CustomAutocomplete from "../CustomComponents/CustomAutocomplete";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import {
    semesterOptions,
    wouldRecommendOptions,
} from "../../util/randomConstants";
import { GET_ATTRIBUTE_DICTS } from "../../util/apiRoutes";
import { customFetch } from "../../util/customFetch";

function CreateReview() {
    const [attributeDicts, setAttributeDicts] = useState({});
    const [loadingInfo, setLoadingInfo] = useState(true);

    const [course, setCourse] = useState({ value: 0, label: "" });
    const [professor, setProfessor] = useState({ value: 0, label: "" });
    const [yearTaken, setYearTaken] = useState("");
    const [semesterTaken, setSemesterTaken] = useState("");

    const [majorMinor, setMajorMinor] = useState("");
    const [overall, setOverall] = useState("");
    const [testDifficulty, setTestDifficulty] = useState("");
    const [workloadQuantity, setWorkloadQuantity] = useState("");
    const [gradingDifficulty, setGradingDifficulty] = useState("");
    const [materialInteresting, setMaterialInteresting] = useState("");
    const [professorRating, setProfessorRating] = useState("");
    const [wouldRecommend, setWouldRecommend] = useState("");
    const [comments, setComments] = useState("");

    const [message, setMessage] = useState("");

    const [allCourses, setAllCourses] = React.useState([]);
    const [allProfessors, setAllProfessors] = React.useState([]);

    const navigate = useNavigate();
    const location = useLocation();

    let formerUrl;
    let formerPid;
    let formerCid;

    // If the user is redirected from a course/professor page, the course id/professor id are passed in
    if (location.state) {
        formerUrl = location.state.formerUrl || "/";
        if (formerUrl === "/addreview") {
            formerUrl = "/";
        }

        formerPid = location.state.formerPid || 0;
        formerCid = location.state.formerCid || 0;
    } else {
        formerUrl = "/";
        formerPid = 0;
        formerCid = 0;
    }

    useEffect(() => {
        // List of courses
        customFetch("/allCourses", {})
            .then((res) => res.json())
            .then((allCourses) => {
                let options = allCourses.map((course) => ({
                    label: course.fullNameStr,
                    value: course.id,
                }));

                setAllCourses(options);
            });

        // List of professors
        customFetch("/allProfessors", {})
            .then((res) => res.json())
            .then((allProfs) => {
                let options = allProfs.map((professor) => ({
                    label: professor.fullName,
                    value: professor.id,
                }));
                setAllProfessors(options);
            });

        // Word options for each question
        customFetch(GET_ATTRIBUTE_DICTS, {})
            .then((res) => res.json())
            .then((dicts) => {
                setAttributeDicts(dicts);
                setLoadingInfo(false);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Set course/professor if redirected from a course/professor page
    useEffect(() => {
        if (formerCid) {
            let formerCourseUrl = "/getCourse/" + formerCid.toString();

            customFetch(formerCourseUrl, {})
                .then((res) => res.json())
                .then((course) => {
                    const myCourse = {
                        value: course.id,
                        label: course.fullNameStr,
                    };
                    setCourse(myCourse);
                });
        }

        if (formerPid) {
            let formerProfessorUrl = "/getProfessor/" + formerPid.toString();

            customFetch(formerProfessorUrl, {})
                .then((res) => res.json())
                .then((professor) => {
                    const myProfessor = {
                        value: professor.id,
                        label: professor.fullName,
                    };
                    setProfessor(myProfessor);
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formerCid, formerPid]);

    // Submit the review
    const handleSubmit = (event) => {
        event.preventDefault();

        customFetch("/addReview",
            {
                method: "POST",
                body: JSON.stringify({
                    courseId: course.value,
                    professorId: professor.value,
                    yearTaken: parseInt(yearTaken),
                    semesterTaken: semesterTaken,
                    majorMinor: majorMinor,
                    overallRating: overall,
                    testDifficulty: testDifficulty,
                    workloadQuantity: workloadQuantity,
                    gradingDifficulty: gradingDifficulty,
                    materialInteresting: materialInteresting,
                    professorRating: professorRating,
                    wouldRecommend: wouldRecommend,
                    reviewDate: new Date(),
                    comments: comments,
                }),
                headers: {
                    "content-type": "application/json",
                    "Access-Control-Allow-Headers": "content-type",
                },
            })
            .then((response) => response.json())
            .then((response) => {
                setMessage(response.message);

                if (
                    response.message === "Review added successfully, thank you!"
                ) {
                    setTimeout(() => {
                        navigate(formerUrl);
                    }, 1000);
                }
            })
            .catch((error) => {
                setMessage(error.message);
            });
    };

    const handleYearTakenChange = (event) => {
        if (event.target.value.length <= 4) {
            setYearTaken(event.target.value);
        }
    };

    return (
        <div className="reviewPage">
            {!loadingInfo && (
                <form className="form" onSubmit={handleSubmit}>
                    <h1 className="addAReviewTitle">Add a Review</h1>
                    <div className="disclaimer">
                        <em>
                            Please use the form below to submit your review. All
                            reviews are anonymous to other visitors, but authorship
                            is recorded for administrative purposes. Also, please be
                            mindful of accents and teaching styles in your review and make
                            sure they don't negatively impact what you write. If you
                            cannot find your professor or course in the lists,
                            click here to {' '}
                            <span>
                                <Link
                                    className="inline-link standard-link"
                                    to="/addprofessor"
                                >
                                    Add a Professor
                                </Link>
                            </span>{" "}
                            or {' '}
                            <span>
                                <Link
                                    className="inline-link standard-link"
                                    to="/addcourse"
                                >
                                    Add a Course
                                </Link>
                            </span>
                            .
                        </em>
                    </div>
                    <div className="questions">
                        <div className="topQuestions">
                            <div className="question">
                                <p className="questionTitle"> Course name </p>
                                <CustomAutocomplete
                                    height={40}
                                    minWidth={260}
                                    options={allCourses}
                                    value={course}
                                    onChange={(event, value) =>
                                        setCourse(value)
                                    }
                                    backgroundColor="black"
                                />
                            </div>
                            <div className="question">
                                <p className="questionTitle"> Professor name</p>
                                <CustomAutocomplete
                                    height={40}
                                    minWidth={260}
                                    options={allProfessors}
                                    value={professor}
                                    onChange={(event, value) =>
                                        setProfessor(value)
                                    }
                                    backgroundColor="black"
                                />
                            </div>
                            <div className="question">
                                <p className="questionTitle">Year Taken</p>
                                <input
                                    className="yearTaken"
                                    type="number"
                                    value={yearTaken}
                                    onChange={handleYearTakenChange}
                                    onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <div className="question">
                                <p className="questionTitle">Semester taken</p>
                                <CustomSelect
                                    value={semesterTaken}
                                    handleChange={(event) =>
                                        setSemesterTaken(event.target.value)
                                    }
                                    options={semesterOptions}
                                    menuItems={[
                                        <MenuItem
                                            className="semesterOption"
                                            key="Fall"
                                            value="Fall"
                                        >
                                            Fall
                                        </MenuItem>,
                                        <MenuItem
                                            className="semesterOption"
                                            key="Spring"
                                            value="Spring"
                                        >
                                            Spring
                                        </MenuItem>,
                                    ]}
                                />
                            </div>
                            <div className="question">
                                <p className="questionTitle">Major/Minor</p>
                                <CustomSelect
                                    value={majorMinor}
                                    handleChange={(event) =>
                                        setMajorMinor(event.target.value)
                                    }
                                    options={semesterOptions}
                                    minWidth="140px"
                                    menuItems={[
                                        <MenuItem key="Major" value="Major">
                                            Major
                                        </MenuItem>,
                                        <MenuItem key="Minor" value="Minor">
                                            Minor
                                        </MenuItem>,
                                        <MenuItem key="Neither" value="Neither">
                                            Neither
                                        </MenuItem>,
                                        <MenuItem
                                            key="Undecided"
                                            value="Undecided"
                                        >
                                            Undecided
                                        </MenuItem>,
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="rightquestions">
                            <div className="ratingButtons">
                                <p className="questionTitle">
                                    Overall Rating (5 being the highest)
                                </p>
                                <RatingButtons
                                    value={overall}
                                    onChange={setOverall}
                                    words={attributeDicts.overallRating}
                                />
                            </div>
                            <div className="ratingButtons">
                                <p className="questionTitle">Test Difficulty</p>
                                <RatingButtons
                                    value={testDifficulty}
                                    onChange={setTestDifficulty}
                                    words={attributeDicts.testDifficulty}
                                />
                            </div>
                            <div className="ratingButtons">
                                <p className="questionTitle">
                                    Workload Quantity
                                </p>
                                <RatingButtons
                                    value={workloadQuantity}
                                    onChange={setWorkloadQuantity}
                                    words={attributeDicts.workloadQuantity}
                                />
                            </div>
                            <div className="ratingButtons">
                                <p className="questionTitle">
                                    Grading Difficulty
                                </p>
                                <RatingButtons
                                    value={gradingDifficulty}
                                    onChange={setGradingDifficulty}
                                    words={attributeDicts.gradingDifficulty}
                                />
                            </div>
                            <div className="ratingButtons">
                                <p className="questionTitle">
                                    Material Interesting
                                </p>
                                <RatingButtons
                                    value={materialInteresting}
                                    onChange={setMaterialInteresting}
                                    words={attributeDicts.materialInteresting}
                                />
                            </div>
                            <div className="ratingButtons">
                                <p className="questionTitle">
                                    Professor Rating
                                </p>
                                <RatingButtons
                                    value={professorRating}
                                    onChange={setProfessorRating}
                                    words={attributeDicts.professorRating}
                                />
                            </div>
                            <div>
                                <div className="ratingButtons">
                                    <p className="questionTitle">
                                        Would recommend to a friend:
                                    </p>
                                    <CustomSelect
                                        className="wouldRecommendSelect"
                                        value={wouldRecommend}
                                        handleChange={(event) =>
                                            setWouldRecommend(
                                                event.target.value
                                            )
                                        }
                                        options={wouldRecommendOptions}
                                        height="30px"
                                        borderRadius="40px"
                                        widthPercent="80px"
                                        menuItems={[
                                            <MenuItem key="Yes" value={1}>
                                                Yes
                                            </MenuItem>,
                                            <MenuItem key="No" value={0}>
                                                No
                                            </MenuItem>,
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className="comment">
                                <div className="commentTitleLine">
                                    <p className="commentTitle">Comments</p>
                                    <p className="commentLength">
                                        {comments.length}/1500 characters
                                    </p>
                                </div>
                                <textarea
                                    className="commentBox black-background-input"
                                    placeholder="Enter your comments here"
                                    value={comments}
                                    onChange={(event) =>
                                        setComments(event.target.value)
                                    }
                                    maxLength="1500"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="bottomLine">
                        <input
                            className="whiteButton whiteButton-submit"
                            type="submit"
                            value="Submit"
                        />
                        <p>{message}</p>
                    </div>
                </form>
            )}
        </div>
    );
}

export default CreateReview;
