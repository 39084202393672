import React, { useEffect, useState } from "react";
import "./Review.css";
import "./ReviewPage.css";
import { useParams } from "react-router-dom";
import ReviewStats from "./ReviewStats";
import PageNotFound from "../Other/PageNotFound";
import ProfessorTitle from "./ReviewPageTitles/ProfessorTitle";
import ProfessorReviews from "./ReviewPagePieces/ProfessorReviews";
import { GET_PROFESSOR } from "../../util/apiRoutes";
import { customFetch } from "../../util/customFetch";

function ProfessorReviewPage() {

    const [selectedCourse, setSelectedCourse] = React.useState(0);

    const [professorInfo, setProfessorInfo] = useState({});
    const [professorNotFound, setProfessorNotFound] = useState(false);
    const { profId } = useParams();
    let professorUrl = GET_PROFESSOR + profId.toString(); // getting professor name

    useEffect(() => {
        // fetch professor name
        customFetch(professorUrl, {})
            .then((res) => res.json())
            .then((professor) => {
                setProfessorInfo(professor);
                if (!professor.fullName) {
                    setProfessorNotFound(true);
                }
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {professorNotFound ? (
                <PageNotFound type="Professor" />
            ) : (
                <div className="ReviewList">
                    <ProfessorTitle professor={professorInfo} />
                    <div
                        id="reviewList-bottom-id"
                        className="reviewList-bottom"
                    >
                        <div className="left">
                            <ProfessorReviews
                                professor={professorInfo}
                                parentSetCourseSelected={setSelectedCourse}
                            />
                        </div>
                        <div className="right">
                            <ReviewStats pid={profId} cid={selectedCourse} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
export default ProfessorReviewPage;
