import React, { useEffect, useState } from "react";
import "./CreateProfessor.css";
import CustomSelect from "../CustomComponents/CustomSelect";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { customFetch } from "../../util/customFetch";
import { ALL_DEPARTMENTS } from "../../util/apiRoutes";

function CreateProfessor() {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [departments, setDepartments] = useState([]);
    const [message, setMessage] = useState("");

    const [allDepartments, setAllDepartments] = React.useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        customFetch(ALL_DEPARTMENTS, {})
            .then((res) => res.json())
            .then((allDepartments) => {
                setAllDepartments(allDepartments);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();

        customFetch("/addProfessor",
            {
                method: "POST",
                body: JSON.stringify({
                    firstName: firstName,
                    lastName: lastName,
                    departments: departments,
                }),
                headers: {
                    "content-type": "application/json",
                    "Access-Control-Allow-Headers": "content-type",
                },
            })
            .then((response) => response.json())
            .then((response) => {
                setMessage(response.message);

                if (
                    response.message ===
                    "Professor added successfully, thank you!"
                ) {
                    setTimeout(() => {
                        navigate("/");
                    }, 1000);
                }
            })
            .catch((error) => {
                setMessage(error.message);
            });
    };

    const handleChange = (event) => {
        setDepartments(event.target.value);
    };

    const departmentOptions = allDepartments.map((dep) => ({
        label: dep.departmentName,
        value: dep.id,
    }));

    return (
        <form onSubmit={handleSubmit} className="newProfessorForm">
            <h1 className="addACourseTitle">Add a Professor</h1>
            <div className="professorDisclaimer">
                <em>
                    Please use the form below to submit a new professor. Before
                    you do, make sure that the professor doesn't already exist.
                </em>
            </div>
            <label className="question">
                <p className="questionTitle">First Name</p>
                <input
                    className="firstNameInput black-background-input"
                    type="string"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                />
            </label>
            <label className="question">
                <p className="questionTitle">Last Name</p>
                <input
                    className="lastNameInput black-background-input"
                    type="string"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                />
            </label>
            <label className="question">
                <p className="questionTitle">Department(s):</p>
                <CustomSelect
                    multiple={true}
                    value={departments}
                    widthPercent="85%"
                    handleChange={handleChange}
                    options={departmentOptions}
                    menuItems={departmentOptions.map((dep) => (
                        <MenuItem
                            className="addCourseDepartment"
                            key={dep.value}
                            value={dep.value}
                            disabled={
                                departments.length >= 3 &&
                                !departments.includes(dep.value)
                            }
                        >
                            {dep.label}
                        </MenuItem>
                    ))}
                />
            </label>
            <div className="bottom-line">
                <input className="whiteButton whiteButton-submit" type="submit" value="Submit" />
                <p>{message}</p>
            </div>
        </form>
    );
}

export default CreateProfessor;
