import { useEffect, useState } from 'react';
import CustomSelect from "../CustomComponents/CustomSelect";
import { MenuItem } from "@mui/material";
import SearchBar from "../HomePage/SearchBar";
import "./NavBar.css"
import { navbarSelectOptions } from "../../util/randomConstants";

function NavBarSearch() {
    const [searchType, setSearchType] = useState("course");

    useEffect(() => {
        const url = window.location.href;
    
        if (url.includes('prof/')) {
            setSearchType('professor')
        } else if(url.includes('department')) {
            setSearchType('department')
        }
      }, []);

    const handleNavBarSearchChange = (e) => {
        setSearchType(e.target.value);
    };

    return (
        <div className="navbar-search">
            <CustomSelect
                value={searchType}
                handleChange={handleNavBarSearchChange}
                options={navbarSelectOptions}
                minWidth={145}
                additionalStyles={{
                    color: "black",
                    backgroundColor: "none",
                    border: "0px solid green",
                    height: "1.5rem",
                    fontWeight: "600",
                    width: "unset",
                }}
                isNavBar={true}
                menuItems={navbarSelectOptions.map((selectOption) => (
                    <MenuItem
                        className="searchBar-menuItem"
                        key={selectOption.value}
                        value={selectOption.value}
                    >
                        {selectOption.label}
                    </MenuItem>
                ))}
            />
            <div className="searchbar-divider"></div>
            <SearchBar searchType={searchType} />
        </div>
    );
}

export default NavBarSearch;
