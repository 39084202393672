import "./StatsPage.css";
import StatsList from "./StatsList";
import { useState } from "react";
import RankingList from "./RankingList";
import OverallStats from "../ReviewPage/OverallStats";

function StatsPage () {

	const [statsTab, setStatsTab] = useState("courses");
	const [rankingTab, setRankingTab] = useState("gradYear");

	// handle tab change in ranking list (right side)
	const handleRankingChange = (newValue) => {
		setRankingTab(newValue);
	};

	const handleChange = (value) => {
		setStatsTab(value)
	};
	
	return (
		<div className="statsPage">
			<div className="top-section">
				<h1 className="titleStats">Review Statistics</h1>
				<OverallStats urlEnding={'/overallStats'}/>
			</div>
			
			<div className="statsPage-content">
				<div className="statsLists cpd-list">
					<div className="filters-line">
						<h1 className="stats-list-title">Top 10</h1>
						<div className="tabDiv"> 
							<p className={statsTab === 'courses' ? 'clicked' : 'not-clicked'} onClick={() => handleChange('courses')}>Courses</p>
							<p className={statsTab === 'professors' ? 'clicked' : 'not-clicked'} onClick={() => handleChange('professors')}>Professors</p>
							<p className={statsTab === 'departments' ? 'clicked' : 'not-clicked'} onClick={() => handleChange('departments')}>Departments</p>
						</div>
					</div>

					{statsTab === "courses" &&
						<StatsList itemType={'course'}/>
					}
					{statsTab === "professors" &&
						<StatsList itemType={'professor'}/>
					}
					{statsTab === "departments" &&
						<StatsList itemType={'department'}/>
					}
				</div>
				<div className="statsLists">
					<div className="filters-line">
						<h1 className="stats-list-title">Highest Contributing</h1>
						<div className="tabDiv">
							<p className={rankingTab === 'gradYear' ? 'clicked' : 'not-clicked'} onClick={() => handleRankingChange('gradYear')}>Class Years</p>
							<p className={rankingTab === 'major' ? 'clicked' : 'not-clicked'} onClick={() => handleRankingChange('major')}>Majors</p>
						</div>
					</div>

					{rankingTab === "gradYear" &&
						<RankingList rankBy={'gradYear'}/>
					}
					{rankingTab === "major" &&
						<RankingList rankBy={'major'}/>
					}
				</div>
			</div>
		</div>
	)
}

export default StatsPage;