import './Review.css';
import '../../util/colors.css';
import { Link } from 'react-router-dom';
import { convertPropWordToColor } from '../../util/randomMethods';

function Review({review}) {
    
    const myDate = new Date(review.reviewDate)
    const dateString = myDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    const reviewProperties = [
        ["Overall Rating", review.overallRating], 
        ["Test Difficulty", review.testDifficulty], 
        ["Workload Quantity", review.workloadQuantity], 
        ["Grading Difficulty", review.gradingDifficulty], 
        ["Material Interesting", review.materialInteresting], 
        ["Professor Rating", review.professorRating]
    ];

	return (
        <div className="Review white-box">
            <div className="topReview">
                <div>
                    <Link to={"/reviews/prof/" + review.professorID} className="profName">Professor {review.profName}</Link>
                    <span><Link className="courseLink" to={"/reviews/course/" + review.courseID}>{review.courseName}</Link></span>
                    <p className="semesterTaken">Taken {review.takenSemester + " " + review.takenYear}</p>
                </div>

                <div className="revTable">
                    {reviewProperties.map((property, index) => {
                        let bold = "";
                        let className = convertPropWordToColor(property[1]);

                        if (property[0] === "Overall Rating") {
                            bold = " bold";
                            property[1] = property[1] + " / 5";
                        }

                        return (
                            <div className={"quality " + className + bold} key={index}>
                                <p className={"leftCol " + className + "-text"}>{property[0]}</p>
                                <p className={"rightCol " + className + "-text"}>{property[1]}</p>
                            </div>
                        )
                    })}
                </div>
            </div>            
			<div className="reviewBottom">
                <p className={review.reviewText === "" ? "reviewText empty-review" : "reviewText"}>{review.reviewText}</p>
                <p className="date">{dateString}</p>
            </div>
        </div>
        
    )
} 
export default Review; 