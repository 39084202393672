import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function AddReviewButton(){
	const location = useLocation();
	const pathname = location.pathname;

	let cid = 0;
	let pid = 0;

	if (pathname.startsWith("/reviews/course/")){
		cid = pathname.split("/")[3];
	} else if (pathname.startsWith("/reviews/prof/")){
		pid = pathname.split("/")[3];
	}

	return (
		<Link to={"/addreview"} state={{ formerUrl: pathname, formerCid: cid, formerPid: pid }}>
			<p className="whiteButton desktop-add-review">Add a Review</p>
			<p className="whiteButton mobile-add-review">Review +</p>
		</Link>
	)
}

export default AddReviewButton;