import { useEffect, useState } from 'react';
import CustomLinearProgress from '../../CustomComponents/CustomLinearProgress';
import './ReviewPageTitle.css'
import './ReviewPageTitleColors.css'
import { titleStatsColorNames } from "../../../util/randomConstants";
import { REVIEW_COUNT_BY_COURSE, REVIEW_COUNT_BY_PROFESSOR } from "../../../util/apiRoutes";
import { customFetch } from "../../../util/customFetch";

export default function ReviewPageTitleStats({onCoursePage, overallRating, wouldRecommendPercentage, objectId}) {

	const [noReviews, setNoReviews] = useState(false);
	let noReviewsUrl = '';

	if (onCoursePage) {
		noReviewsUrl = REVIEW_COUNT_BY_COURSE + objectId.toString();
	} else {
		noReviewsUrl = REVIEW_COUNT_BY_PROFESSOR + objectId.toString();
	}

	useEffect(() => {
		if (objectId) {
			customFetch(noReviewsUrl, {})
				.then((res) => res.json())
				.then((count) => {
					if (!count) {
						setNoReviews(true);
					}
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function returnColor(prop) {
		if (prop >= 90) {
			return 0;
		} else if (prop >= 70) {
			return 1;
		} else if (prop >= 50) {
			return 2;
		} else if (prop >= 30) {
			return 3;
		} else {
			return 4;
		}
	}

	const overallRatingClassName = titleStatsColorNames[returnColor(overallRating * 20)];
	const wouldRecommendPercentageClassName = titleStatsColorNames[returnColor(wouldRecommendPercentage)];

	return (
		<>
			{!noReviews &&
				<div className="title-overall-stats">
					{overallRating !== -1 ?
						<p className={"title-overall-stat-line stat-line-" + overallRatingClassName}>
							Overall Rating: {overallRating} out of 5
						</p>
						:
						<CustomLinearProgress
							minHeight={45}
							minWidth={230}
							minMobileHeight={45}
							minMobileWidth={"40vw"}
						/>
					}
					{wouldRecommendPercentage !== -1 ?
						<p className={"title-overall-stat-line stat-line-" + wouldRecommendPercentageClassName}>
							{wouldRecommendPercentage}% of students recommend
						</p>
						:
						<CustomLinearProgress
							minHeight={45}
							minWidth={230}
							minMobileHeight={45}
							minMobileWidth={"40vw"}
						/>
					}
				</div> 
			}
		</>
	);
}