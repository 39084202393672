import "./SearchBar.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
    ALL_COURSES,
    ALL_DEPARTMENTS,
    ALL_PROFESSORS,
} from "../../util/apiRoutes";
import { customFetch } from "../../util/customFetch";

function SearchBar({ searchType }) {

    const [allCourses, setAllCourses] = useState([]);
    const [allProfessors, setAllProfessors] = useState([]);
    const [allDepartments, setAllDepartments] = useState([]);

    const [searchInput, setSearchInput] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [searchItems, setSearchItems] = useState([]);
    const [emptyInput, setEmptyInput] = useState(true);
    const [loadingData, setLoadingData] = useState(true);

    const placeholder = "Search for a " + searchType;
    const addLineText = "Don't see the " + searchType + " you're looking for?";
    const addLinkText = "Add a " + searchType;
    const link = "/add" + searchType;

    const location = useLocation();

    let buttonURL;

    if (searchType === "course") {
        buttonURL = "/reviews/course/";
    } else if (searchType === "professor") {
        buttonURL = "/reviews/prof/";
    } else {
        buttonURL = "/department/";
    }

    useEffect(() => {
        const fetchAllCourses = async () => {
            try {
                const response = await customFetch(ALL_COURSES,
                    {});
                const data = await response.json();
                setAllCourses(data);
            } catch (error) {
                // Handle error if any
            }
        };

        const fetchAllProfs = async () => {
            try {
                const response = await customFetch(ALL_PROFESSORS,
                    {});
                const data = await response.json();
                setAllProfessors(data);
            } catch (error) {
                // Handle error if any
            }
        };

        const fetchAllDepts = async () => {
            try {
                const response = await customFetch(ALL_DEPARTMENTS,
                    {});
                const data = await response.json();
                setAllDepartments(data);
            } catch (error) {
                // Handle error if any
            }
        };

        fetchAllCourses();
        fetchAllProfs();
        fetchAllDepts();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSearchItems([]);
        setSearchInput("");
        setFilteredData([]);
        setEmptyInput(true);

        if (searchType === "course" && !allCourses.length) {
            setLoadingData(true);
        } else if (searchType === "professor" && !allProfessors.length) {
            setLoadingData(true);
        } else if (searchType === "department" && !allDepartments.length) {
            setLoadingData(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchType]);

    useEffect(() => {
        if (searchType === "course" && allCourses.length) {
            setSearchItems(allCourses);
            setLoadingData(false);
        } else if (searchType === "professor" && allProfessors.length) {
            setSearchItems(allProfessors);
            setLoadingData(false);
        } else if (searchType === "department" && allDepartments.length) {
            setSearchItems(allDepartments);
            setLoadingData(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchType, allCourses, allProfessors, allDepartments]);

    useEffect(() => {
        if (searchInput !== "") {
            handleChange({ target: { value: searchInput } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingData]);

    const handleChange = (e) => {
        const newSearchInput = e.target.value;
        setSearchInput(newSearchInput);

        const filter = searchItems.filter((value) => {
            if (searchType === "department") {
                return (
                    value.departmentName
                        .toLowerCase()
                        .includes(newSearchInput.toLowerCase()) ||
                    value.departmentAbbreviation
                        .toLowerCase()
                        .includes(newSearchInput.toLowerCase())
                );
            } else if (searchType === "course") {
                let compare = value.fullNameStr;
                return compare
                    .toLowerCase()
                    .includes(newSearchInput.toLowerCase());
            } else {
                return value.fullName
                    .toLowerCase()
                    .includes(newSearchInput.toLowerCase());
            }
        });
        if (newSearchInput === "") {
            setFilteredData([]);
            setEmptyInput(true);
        } else {
            setFilteredData(filter);
            setEmptyInput(false);
        }
    };

    const handleLinkClick = () => {
        if (location.pathname !== "/") {
            setSearchInput("");
            setFilteredData([]);
            setEmptyInput(true);
            location.reload();
        }
    };

    return (
        <div
            className={
                location.pathname === "/"
                    ? "searchBar"
                    : "searchBar navbar-search-bar"
            }
        >
            <div className="searchInput">
                <input
                    type="text"
                    placeholder={placeholder}
                    onChange={handleChange}
                    className="searchInput-input"
                    value={searchInput}
                />
                {searchType !== "department" && (
                    <div className="add-line">
                        <p>{addLineText}</p>
                        <Link to={link} className="whiteButton">
                            {addLinkText}
                        </Link>
                    </div>
                )}
            </div>
            {Boolean(filteredData.length) && (
                <div className="searchResults">
                    {filteredData.map((searchItem) => {
                        let searchItemLink = buttonURL + searchItem.id;
                        let text =
                            searchType === "department"
                                ? searchItem.departmentName
                                : searchType === "course"
                                ? searchItem.fullNameStr
                                : searchItem.fullName;
                        return (
                            <Link
                                to={searchItemLink}
                                key={searchItem.id}
                                onClick={handleLinkClick}
                                className="searchItem"
                            >
                                {text}
                            </Link>
                        );
                    })}
                </div>
            )}
            {!filteredData.length && !emptyInput && (
                <div className="searchResults">
                    <p className="searchItem no-results">
                        {loadingData
                            ? "Loading results..."
                            : "No results found"}
                    </p>
                </div>
            )}
        </div>
    );
}
export default SearchBar;
