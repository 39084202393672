import React, { useEffect, useState } from "react";
import CustomLinearProgress from "../../CustomComponents/CustomLinearProgress";
import "./ReviewPageTitle.css";
import { useParams } from "react-router-dom";
import CopyToClipboardLink from "../../Other/CopyToClipboard";
import { Link } from "react-router-dom";
import ReviewPageTitleStats from "./ReviewPageTitleStats";
import { PROFESSOR_DEPARTMENTS, PROFESSOR_TITLE_STATS } from "../../../util/apiRoutes";
import { customFetch } from "../../../util/customFetch";

function ProfessorTitle({professor}) {

    const [professorOverallRating, setProfessorOverallRating] = useState(-1);
    const [professorWouldRecommend, setProfessorWouldRecommend] = useState(-1);
	const [professorDepartments, setProfessorDepartments] = useState([]);

	const [departmentLoading, setDepartmentLoading] = useState(true); // loading for departments
    const [professorLoading, setProfessorLoading] = useState(true); // loading for professor

	const { profId } = useParams();

    let titleStatsUrl = PROFESSOR_TITLE_STATS + profId.toString() + "/course/0" // getting title stats for a professor
    let professorDepartmentUrl = PROFESSOR_DEPARTMENTS + profId.toString(); // getting all departments that a prof teaches in

	useEffect(() => {
		// professor courses
        customFetch(professorDepartmentUrl, {})
            .then((res) => res.json())
            .then((professorDepartments) => {
                setProfessorDepartments(professorDepartments.slice(0,3));
                setDepartmentLoading(false);
            });

        customFetch(titleStatsUrl, {})
            .then((res) => res.json())
            .then((titleStats) => {
                setProfessorOverallRating(titleStats.overallRating);
                setProfessorWouldRecommend(Math.round(titleStats.wouldRecommend * 100));
            });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    useEffect(() => {
        if (professor.fullName) {
            setProfessorLoading(false);
        }
    }, [professor]);

    return (
        <div className="review-page-title">
            <div className="professor-title-top">
                <div className="departmentList">
                    {departmentLoading ?
                        <CustomLinearProgress
                            minHeight={50}
                            minWidth={115}
                            minMobileHeight={45}
                            minMobileWidth={85}
                        />
                        :
                        <>
                            {professorDepartments.map((dept) => (
                                <Link key={dept.id} className="abbr department-abbr" to={"/department/" + dept.id}>{dept.departmentAbbreviation}</Link>
                            ))}
                        </>
                    }
                </div>
                <div className="title-bottom-row">
                    {professorLoading ?
                        <CustomLinearProgress
                            minHeight={60}
                            minWidth={400}
                            minMobileHeight={45}
                            minMobileWidth={250}
                        />
                        :
                        <>
                            <p className="courseTitle">{professor.fullName}</p>
                            <CopyToClipboardLink text={professor.fullName} />
                        </>
                    }
                </div>
            </div>
            <ReviewPageTitleStats
                onCoursePage={false}
                overallRating={professorOverallRating}
                wouldRecommendPercentage={professorWouldRecommend}
                objectId={profId}
            />
        </div>
    );
}

export default ProfessorTitle;