import React from 'react'
import './SignInPage.css'

function SignInPage() {
	let baseURLWithoutPath = window.location.protocol + "//" + window.location.hostname;
	if (baseURLWithoutPath.includes('localhost')) {
		baseURLWithoutPath = baseURLWithoutPath.replace('localhost', '127.0.0.1');
		baseURLWithoutPath += ":8080";
	} else if (baseURLWithoutPath.includes('127.0.0.1')) {
		baseURLWithoutPath = baseURLWithoutPath.replace('127.0.0.1', 'localhost');
		baseURLWithoutPath += ":8080";
	} else {
		baseURLWithoutPath = "https://course-reviews.students.bowdoin.edu";
	}

	return (
		<div className="signIn">
			<div className="signInLeftSide">
				<header className="HomePage-header">
					<p>Bowdoin College</p>
					<p>Course Reviews</p>
				</header>
				<p>Professor, Course, and Department Reviews by and for Bowdoin Students</p>
				<div className='bottomLine'>
					<a className="whiteButton whiteButton-submit" href={baseURLWithoutPath + "/signin"}>
						Sign in with Okta →
					</a>
				</div> 
			</div>
			<div className="signInRightSide">
				<img src="./PolarBear2.jpg" alt="Polar bear wearing a Bowdoin sweater leaning on a stack of books"/>
			</div>
		</div>
	)
}

export default SignInPage;