import "./Footer.css";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { loadFooterRightAwayPages } from "../../util/randomConstants";

function Footer() {

	const [showFooter, setShowFooter] = useState(false);
	const location = useLocation();
	const loadThisRightAway = loadFooterRightAwayPages.includes(location.pathname);


	useEffect(() => {
		setShowFooter(false);

		if (loadThisRightAway) {
			setShowFooter(true);
			
		} else if (location.pathname === "/login") {
			setShowFooter(false);
		} else if (location.pathname !== "/login/callback") {
			setTimeout(() => {
				setShowFooter(true);
			}, 500);
		} 

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	return (
		<div className={loadThisRightAway ? "footer" : "footer light-footer"}>
			{ showFooter && 
				<p className="copyRightText">Created by Zane Bookbinder '24 and Olivia Wirsching '24</p>
			}
		</div>
	);
}

export default Footer;