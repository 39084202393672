import React, { useState, useEffect, useRef } from "react";
import CustomLinearProgress from "../../CustomComponents/CustomLinearProgress";
import "./ReviewList.css";
import Review from "../Review";
import AddReviewButton from "../../NavBar/AddReviewButton";
import CustomSelect from "../../CustomComponents/CustomSelect";
import { MenuItem } from "@mui/material";
import { useParams } from "react-router-dom";
import {
    COURSE,
    COURSE_PROFESSORS,
    LIMIT,
    PROF,
    REVIEWS,
    REVIEW_COUNT_BY_COURSE,
} from "../../../util/apiRoutes";
import { customFetch } from "../../../util/customFetch";

function CourseReviews({ course, parentSetProfessorSelected }) {

    const defaultReviewsShown = 25;

    // All Reviews
    const [loadingAllReviews, setLoadingAllReviews] = useState(false);

    // Sorted Data
    const [reviewListData, setData] = useState([]);
    const [dataHighToLow, setDataHighToLow] = useState([]);
    const [dataLowToHigh, setDataLowToHigh] = useState([]);
    const [tabValue, setTabValue] = useState(0);

    const [professors, setProfessors] = React.useState([
        { id: 0, fullName: "All Professors" },
    ]);

    const [selectedProfessor, setSelectedProfessor] = React.useState(0);
    const [reviewCount, setReviewCount] = useState(0);

    const [reviewsLoading, setReviewsLoading] = useState(true);
    const [noReviews, setNoReviews] = useState(false);

    const { courseId } = useParams();

    let profUrl = COURSE_PROFESSORS + courseId.toString(); // getting all professors for a course

    let reviewURLpart1 = REVIEWS; // add type: date, lth, htl
    let reviewURLpart2 =
        COURSE +
        courseId.toString() +
        PROF +
        selectedProfessor.toString() +
        LIMIT +
        defaultReviewsShown.toString();
    let reviewCountURL = REVIEW_COUNT_BY_COURSE + courseId.toString();

    const [reviewsWidth, setReviewsWidth] = useState(0);
    const reviewsRef = useRef(null);

    useEffect(() => {
        fetchReviewData(reviewURLpart2, true);

        // course professors
        customFetch(profUrl, {})
            .then((res) => res.json())
            .then((professors) => {
                professors.unshift({ id: 0, fullName: "All Professors" });
                setProfessors(professors);
            });

        // review count
        customFetch(reviewCountURL, {})
            .then((res) => res.json())
            .then((reviewCount) => {
                setReviewCount(reviewCount);
            });

        if (reviewsRef.current) {
            setReviewsWidth(reviewsRef.current.offsetWidth);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // HANDLES CHANGE IN SORTING METHOD
    const handleSortChange = (event, newValue) => {
        setTabValue(event.target.value);
    };

    // HANDLES CHANGE IN SELECTED PROFESSOR
    const handleFilterChange = (event) => {
        setReviewsLoading(true);
        setSelectedProfessor(event.target.value);
        parentSetProfessorSelected(event.target.value);

        let ending =
            "/course/" +
            courseId.toString() +
            "/prof/" +
            event.target.value.toString() +
            "/limit/" +
            defaultReviewsShown.toString();

        fetchReviewData(ending, false);

        if (!event.target.value) {
            customFetch(reviewCountURL, {})
                .then((res) => res.json())
                .then((reviewCount) => {
                    setReviewCount(reviewCount);
                });
        }
    };

    // FETCHES REVIEWS FROM ALL THREE SORTING METHODS
    const fetchReviewData = (endOfUrl, isFirstCall) => {
        // reviews sorted by data
        customFetch(
            reviewURLpart1 + "date" + endOfUrl,
            {})
            .then((res) => res.json())
            .then((reviewListData) => {
                setData(reviewListData);

                // if no reviews on the overall page (no filter selected), set no reviews to true
                if (isFirstCall) {
                    setNoReviews(!reviewListData.length);

                    // background of no-reviews page should be fully black
                    if (!reviewListData.length) {
                        document.getElementById(
                            "reviewList-bottom-id"
                        ).style.backgroundColor = "black";
                    }
                }

                // if reviewCount < 25 or all reviews are being shown, set reviewCount to reviewListData.length
                if (
                    reviewListData.length < defaultReviewsShown ||
                    endOfUrl.includes("max")
                ) {
                    setReviewCount(reviewListData.length);
                }

                setLoadingAllReviews(false);
                setReviewsLoading(false);
            });

        // reviews sorted by score high to low
        customFetch(
            reviewURLpart1 + "htl" + endOfUrl,
            {})
            .then((res) => res.json())
            .then((dataHighToLow) => {
                setDataHighToLow(dataHighToLow);
            });

        // reviews sorted by score low to high
        customFetch(
            reviewURLpart1 + "lth" + endOfUrl,
            {})
            .then((res) => res.json())
            .then((dataLowToHigh) => {
                setDataLowToHigh(dataLowToHigh);
            });
    };

    // FETCHES ALL REVIEWS (NOT JUST THE FIRST 25)
    const showAllReviews = () => {
        setLoadingAllReviews(true);

        let ending =
            "/course/" +
            courseId.toString() +
            "/prof/" +
            selectedProfessor.toString() +
            "/limit/max";

        fetchReviewData(ending, false);
    };

    const tabOptions = [
        { value: 0, label: "Most Recent" },
        { value: 1, label: "Score: High to Low" },
        { value: 2, label: "Score: Low to High" },
    ];

    // Function to find the professor's name based on their id
    function findFullNameById(id) {
        for (let i = 0; i < professors.length; i++) {
            if (professors[i].id === id) {
                return professors[i].fullName;
            }
        }
        return null;
    }

    return (
        <>
            {(reviewsLoading || (!reviewsLoading && !noReviews)) && (
                <div className="reviews" ref={reviewsRef}>
                    <div className="filters">
                        <div className="filter1">
                            <p className="filter-text">Filter </p>
                            <CustomSelect
                                value={selectedProfessor}
                                handleChange={handleFilterChange}
                                options={professors.map((professor) => ({
                                    label: professor.fullName,
                                    value: professor.id,
                                }))}
                                height="40px"
                                borderRadius="10px"
                                additionalStyles={{
                                    padding: "0px",
                                    marginTop: "10px",
                                    width: "200px",
                                }}
                                menuItems={professors.map((professor) => (
                                    <MenuItem
                                        key={professor.id}
                                        value={professor.id}
                                    >
                                        {professor.fullName}
                                    </MenuItem>
                                ))}
                            />
                        </div>
                        <div className="filter2">
                            <p className="filter-text">Sort</p>
                            <CustomSelect
                                value={tabValue}
                                handleChange={handleSortChange}
                                options={tabOptions}
                                height="40px"
                                borderRadius="10px"
                                additionalStyles={{
                                    marginTop: "10px",
                                    minWidth: "185px",
                                }}
                                menuItems={tabOptions.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            />
                        </div>

                        {!reviewsLoading && (
                            <div className="reviewCount">
                                {reviewCount}{" "}
                                {reviewCount === 1 ? "Review" : "Reviews"}{" "}
                            </div>
                        )}
                    </div>

                    {reviewsLoading ? (
                        <div
                            style={{ marginRight: "20px", marginBlock: "15px" }}
                        >
                            <CustomLinearProgress
                                lightMode={true}
                                minWidth={reviewsWidth}
                                minHeight={200}
                            />
                        </div>
                    ) : (
                        <>
                            {!tabValue &&
                                reviewListData.map((review) => {
                                    return (
                                        <Review
                                            review={review}
                                            key={review.id}
                                        />
                                    );
                                })}
                            {tabValue === 1 &&
                                dataHighToLow.map((review) => {
                                    return (
                                        <Review
                                            review={review}
                                            key={review.id}
                                        />
                                    );
                                })}
                            {tabValue === 2 &&
                                dataLowToHigh.map((review) => {
                                    return (
                                        <Review
                                            review={review}
                                            key={review.id}
                                        />
                                    );
                                })}
                            {reviewCount > 25 &&
                                reviewListData.length <= 25 && (
                                    <div className="show-all-reviews-div">
                                        {loadingAllReviews ? (
                                            <CustomLinearProgress
                                                lightMode={true}
                                                minWidth={reviewsWidth}
                                                minHeight={120}
                                                minMobileHeight={50}
                                            />
                                        ) : (
                                            <button
                                                className="show-all-button white-box"
                                                onClick={showAllReviews}
                                            >
                                                Show All Reviews
                                            </button>
                                        )}
                                    </div>
                                )}

                            {!reviewListData.length && (
                                <div className="noReviews-coursereviewsfilter">
                                    <p className="noReviews">
                                        {findFullNameById(selectedProfessor)}{" "}
                                        has no reviews.
                                    </p>
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
            {!reviewsLoading && noReviews && (
                <div className="noReviewsPage">
                    <div className="noReviews">
                        {course.courseName} has no reviews.
                    </div>
                    <AddReviewButton />
                </div>
            )}
        </>
    );
}

export default CourseReviews;
