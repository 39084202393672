import React, { useState, useEffect } from "react";
import CustomAutocomplete from "../CustomComponents/CustomAutocomplete.js";
import "./ProfilePage.css";
import { Link } from "react-router-dom";
import Review from "../ReviewPage/Review";
import CustomLinearProgress from "../CustomComponents/CustomLinearProgress";
import AddReviewButton from "../NavBar/AddReviewButton";
import { FaRegStar, FaStar } from "react-icons/fa";
import { defaultUser } from "../../util/randomConstants";
import {
    ALL_DEPARTMENTS,
    GET_USER_BY_ID,
    STAR_OR_UNSTAR_COURSE,
    UPDATE_USER,
    USER_REVIEWS,
    USER_STARRED_COURSES,
} from "../../util/apiRoutes";
import { customFetch } from "../../util/customFetch.js";

function ProfilePage() {

    const [changeMode, setChangeMode] = useState(false);
    const [gradYear, setGradYear] = useState(0);
    const [major1, setMajor1] = useState({ value: 0, label: "None" });
    const [major2, setMajor2] = useState({ value: 0, label: "None" });
    const [minor, setMinor] = useState({ value: 0, label: "None" });
    const [allDepartments, setAllDepartments] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [starredCourses, setStarredCourses] = useState([]);
    const [loadingUserInfo, setLoadingUserInfo] = useState(true);
    const [loadingUserReviews, setLoadingUserReviews] = useState(true);
    const [loadingStarredCourses, setLoadingStarredCourses] = useState(true);

    const [user, setUser] = useState(defaultUser);

    // info for user major/minor rows
    const autoCompleteProps = [
        [gradYear, setGradYear, "Grad Year", user.gradYear, user.gradYear],
        [major1, setMajor1, "Major 1 ", user.major1, user.major1Text],
        [major2, setMajor2, "Major 2 ", user.major2, user.major2Text],
        [minor, setMinor, "Minor ", user.minor, user.minorText],
    ];

    useEffect(() => {
        // get user info
        customFetch(GET_USER_BY_ID, {})
            .then((res) => res.json())
            .then((data) => {
                setUser(data);
                setLoadingUserInfo(false);
            });

        // get list of departments
        customFetch(ALL_DEPARTMENTS, {})
            .then((res) => res.json())
            .then((allDepartments) => {
                allDepartments.unshift({ id: 0, departmentName: "None" });

                let departmentOptionPlaceholder = allDepartments.map(
                    (department) => {
                        return {
                            value: department.id,
                            label: department.departmentName,
                        };
                    }
                );
                setAllDepartments(departmentOptionPlaceholder);
            });

        // get all reviews written by user
        customFetch(USER_REVIEWS,
            {})
            .then((res) => res.json())
            .then((reviews) => {
                setReviews(reviews);
                setLoadingUserReviews(false);
            });

        // get all courses starred by user
        customFetch(USER_STARRED_COURSES,
            {})
            .then((res) => res.json())
            .then((starredCourses) => {
                setStarredCourses(starredCourses);
                setLoadingStarredCourses(false);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleEditOrSave = () => {
        if (changeMode) {
            let currentYear = new Date().getFullYear();
            if (major1 === null || major2 === null || minor === null){
                alert("Please select an option or 'None' for all fields.")
                return;
            } else if (gradYear > currentYear + 5 || gradYear < currentYear - 1) {
                let alertStr = "Your graduation year must fall between " + (currentYear - 1) + " and " + (currentYear + 5);
                alert(alertStr);
                return;
            }

            customFetch(
                UPDATE_USER,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        gradyear: gradYear,
                        major1: major1.value,
                        major2: major2.value,
                        minor: minor.value,
                    }),
                })
                .then((res) => res.json())
                .catch((error) => {
                    console.error("Error:", error);
                });

            setUser({
                ...user,
                gradYear: gradYear,
                major1: major1.value,
                major2: major2.value,
                minor: minor.value,
                major1Text: major1.label,
                major2Text: major2.label,
                minorText: minor.label,
            });
        } else {
            setMajor1(
                allDepartments.find((dep) => dep.value === user.major1) || {
                    value: 0,
                    label: "None",
                }
            );
            setMajor2(
                allDepartments.find((dep) => dep.value === user.major2) || {
                    value: 0,
                    label: "None",
                }
            );
            setMinor(
                allDepartments.find((dep) => dep.value === user.minor) || {
                    value: 0,
                    label: "None",
                }
            );
            setGradYear(user.gradYear);
        }
        setChangeMode(!changeMode);
    };

    const handleStar = (id) => {
        // change active to false for this course
        let starredCoursesCopy = [...starredCourses];
        let course = starredCoursesCopy.find((course) => course.id === id);
        course.active = !course.active;
        setStarredCourses(starredCoursesCopy);

        customFetch(
            STAR_OR_UNSTAR_COURSE + id,
            {
                method: "POST",
                body: JSON.stringify({
                    courseId: id,
                }),
                headers: {
                    "content-type": "application/json",
                    "Access-Control-Allow-Headers": "content-type",
                },
            });
    };

    return (
        <div className="profilePage">
            <div className="left">
                <div className="profilePageInfo gray-box">
                    <div className="topLineProfile">
                        <h1 className="profilePageTitle">Profile</h1>
                        <button
                            className="whiteButton edit-save-button"
                            onClick={handleEditOrSave}
                        >
                            {changeMode ? "Save" : "Edit"}
                        </button>
                    </div>

                    {loadingUserInfo ? (
                        <CustomLinearProgress
                            minWidth={330}
                            minHeight={190}
                            minMobileWidth={300}
                        />
                    ) : (
                        <div className="lines">
                            <div className="line">
                                <p className="lineTitle">Username</p>
                                <p className="detail">{user.username}</p>
                            </div>
                            {autoCompleteProps.map((prop, index) => {
                                return (
                                    <div className="line" key={index}>
                                        <p className="lineTitle">{prop[2]}</p>
                                        {changeMode ? (
                                            <>
                                                {prop[2] === 'Grad Year' ? (
                                                   <input
                                                        className="courseNumberInput black-background-input grad-year-input"
                                                        type="number"
                                                        value={gradYear}
                                                        onChange={(event) => setGradYear(event.target.value)} 
                                                    />
                                                )
                                                :
                                                (
                                                    <CustomAutocomplete
                                                        height={30}
                                                        minWidth={225}
                                                        options={allDepartments}
                                                        value={prop[0] || null}
                                                        onChange={(event, value) =>
                                                            prop[1](value)
                                                        }
                                                        isOptionEqualToValue={(
                                                            option,
                                                            value
                                                        ) =>
                                                            option.value === value.value
                                                        }
                                                        fontSize={15}
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {prop[2] === 'Grad Year' ? (
                                                    <div className='grad-year-display-text'>{prop[3]}</div>
                                                )
                                                :
                                                (
                                                    <>
                                                        {prop[4] === "None" ? (
                                                            <p className="detail">
                                                                None
                                                            </p>
                                                        ) : (
                                                            <Link
                                                                className="detail detail-link"
                                                                to={
                                                                    "/department/" +
                                                                    prop[3]
                                                                }
                                                            >
                                                                {prop[4]}
                                                            </Link>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
                <div className="starred-courses gray-box">
                    <h1 className="starred-courses-title">Starred Courses</h1>

                    {loadingStarredCourses ? (
                        <CustomLinearProgress
                            minWidth={330}
                            minHeight={190}
                            minMobileWidth={300}
                        />
                    ) : (
                        <div className="starred-courses-list">
                            {starredCourses.length ? (
                                starredCourses.map((course) => {
                                    return (
                                        <Link
                                            key={course.id}
                                            className="starred-course-line standard-link"
                                            to={'/reviews/course/' + course.id}
                                        >
                                            {course.active ? (
                                                <FaStar
                                                    onClick={() =>
                                                        handleStar(course.id)
                                                    }
                                                    className="star-icon"
                                                ></FaStar>
                                            ) : (
                                                <FaRegStar
                                                    onClick={() =>
                                                        handleStar(course.id)
                                                    }
                                                    className="star-icon"
                                                ></FaRegStar>
                                            )}
                                            {course.courseName}
                                        </Link>
                                    );
                                })
                            ) : (
                                <p>You don't have any starred courses</p>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {loadingUserReviews ? (
                <CustomLinearProgress
                    minWidth={954}
                    minHeight={280}
                    minMobileWidth={100}
                />
            ) : (
                <>
                    {reviews.length ? (
                        <div className="user-reviews">
                            <h1 className="your-reviews-title">
                                Your {reviews.length}{" "}
                                {reviews.length === 1 ? "review" : "reviews"}
                            </h1>
                            {reviews.map((review) => {
                                return (
                                    <Review
                                        className="review"
                                        review={review}
                                        key={review.id}
                                    ></Review>
                                );
                            })}
                        </div>
                    ) : (
                        <div className="no-reviews">
                            <h1 className="no-reviews-title">
                                You have no reviews
                            </h1>
                            <AddReviewButton />
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default ProfilePage;
